import { FC } from "react";
import GenericCrudTable from "../tables/genericCrudTable";
import { t } from "i18next";

interface Props {
  probeId: any;
  customRowActions?: any;
  customCreatePresetValues?: any;
  additionalSubmitDataForCreate?: any;
  additionalOnSuccessfulCreateFunction?: any;
  additionalRoutePartsForDelete?: any;
  customDeleteHandler?: any;
  resetFormtable?: boolean;
  customDeleteFunction?: any;
}

const ProbeEditorFormCrud: FC<Props> = ({
  probeId,
  customRowActions,
  customCreatePresetValues,
  additionalSubmitDataForCreate,
  additionalOnSuccessfulCreateFunction,
  additionalRoutePartsForDelete,
  customDeleteHandler,
  resetFormtable,
  // eslint-disable-next-line
  customDeleteFunction,
}) => {
  const columns = [
    { header: "ID", accessorKey: "id", id: "id" },
    {
      header: t("name"),
      accessorKey: "name",
      id: "name",
      required: true,
      errorMessage: t("field_required"),
      enableForCreate: true,
    },
    {
      header: t("fillable_by_portal_user_short"),
      enableGlobalFilter: false,
      accessorKey: "editable_by_subject",
      enableForCreate: true,
      selectOptions: [
        { value: true, label: t("yes"), id: "true" },
        { value: false, label: t("no"), id: "false" },
      ],
      accessorFn: (row: any) => {
        if (row.editable_by_subject) {
          return t("yes");
        } else return t("no");
      },
    },
    {
      header: t("widget_for", { view: t("appointment_dashboard") }),
      accessorKey: "appointment_dashboard_widget",
      enableForCreate: true,
      selectOptions: [
        { value: true, label: t("yes"), id: "true" },
        { value: false, label: t("no"), id: "false" },
      ],
      accessorFn: (row: any) => {
        if (row.appointment_dashboard_widget !== null) {
          if (row.appointment_dashboard_widget) {
            return t("yes");
          } else {
            return t("no");
          }
        } else return "-";
      },
    },
    {
      header: t("widget_for", { view: t("subject_company_overview") }),
      accessorKey: "subject_overview_widget",
      enableForCreate: true,
      selectOptions: [
        { value: true, label: t("yes"), id: "true" },
        { value: false, label: t("no"), id: "false" },
      ],
      accessorFn: (row: any) => {
        if (row.subject_overview_widget !== null) {
          if (row.subject_overview_widget) {
            return t("yes");
          } else {
            return t("no");
          }
        } else return "-";
      },
    },
    {
      header: t("widget_hidden_form"),
      accessorKey: "hidden",
      enableForCreate: true,
      selectOptions: [
        { value: true, label: t("yes"), id: "true" },
        { value: false, label: t("no"), id: "false" },
      ],
      accessorFn: (row: any) => {
        if (row.hidden !== null) {
          if (row.hidden) {
            return t("yes");
          } else {
            return t("no");
          }
        } else return "-";
      },
    },
    {
      header: t("widget_priority"),
      accessorKey: "widget_priority",
      enableForCreate: true,
      textfieldType: "number",
    },
    {
      header: t("widget_id"),
      accessorKey: "widget_id",
      enableForCreate: true,
    },
    {
      header: t("widget_title"),
      accessorKey: "widget_title",
      enableForCreate: true,
    },
  ];

  return (
    <>
      <GenericCrudTable
        entity="form"
        columns={columns}
        customPageSize={5}
        additionalUrlParameter={{
          probe: probeId,
        }}
        customRowActions={customRowActions}
        customCreatePresetValues={customCreatePresetValues}
        additionalSubmitDataForCreate={additionalSubmitDataForCreate}
        additionalOnSuccessfulCreateFunction={
          additionalOnSuccessfulCreateFunction
        }
        additionalRoutePartsForDelete={additionalRoutePartsForDelete}
        customDeleteHandler={customDeleteHandler}
        uploadSuccessful={resetFormtable}
      />
    </>
  );
};

export default ProbeEditorFormCrud;
