import React, { FC, useEffect, useState } from "react";
import Restricted from "../../consumer/restricted";
import { useNavigate, useParams } from "react-router-dom";
import GenericCrudTable from "../tables/genericCrudTable";
import { t } from "i18next";
import { IconButton, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { api } from "../../helper/api";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";

const TrackedServiceCompanyOverrideTemplate: FC = () => {
  const [error, setError] = useState<any>();
  const [subject, setSubject] = useState<any>(null);

  const { subjectId } = useParams() as {
    subjectId: string;
  };

  useEffect(() => {
    if (subjectId) {
      api.genericApiRequest({
        entity: "subject",
        entityId: subjectId,
        method: "get",
        parametersToRender: { depth: "0" },
        successHandler: (res: any) => {
          setSubject(res.data);
        },
        failHandler: (error: any) => {
          setError(error);
        },
      });
    }
  }, [subjectId]);

  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate("/admin/companyList");
  };

  let heading = t("buildings");
  if (subject) {
    heading =
      t("trackedservice_companyOverride") + ` - ${subject.company.name}`;
  }

  const trackedServiceCompanyColumns = [
    { header: "ID", accessorKey: "id", id: "id" },
    {
      header: t("trackedservice_template"),
      accessorKey: "tracked_service_template",
      id: "tracked_service_template",
      entity: "trackedServiceTemplate",
      Cell: ({ cell }: any) => {
        return <span>{cell.getValue().name}</span>;
      },
      enableForCreate: true,
      required: true,
      errorMessage: t("field_required"),
    },
    {
      header: t("price"),
      accessorKey: "price",
      id: "price",
      enableForCreate: true,
      textfieldType: "number",
      required: true,
      errorMessage: t("field_required"),
    },
  ];

  const additionalDataforCreate = { company: subject?.company?.id };

  return (
    <Restricted permissionRequired="view_trackedservicetemplatecompanyoverride">
      {subjectId && (
        <Tooltip placement="right" title={t("back_to", { name: t("company") })}>
          <IconButton
            onClick={handleNavigateBack}
            sx={{ alignSelf: "flex-start" }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
      )}
      <GenericCrudTable
        additionalSubmitDataForCreate={additionalDataforCreate}
        heading={heading}
        entity="trackedServiceTemplateCompanyOverride"
        columns={trackedServiceCompanyColumns}
      />
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </Restricted>
  );
};

export default TrackedServiceCompanyOverrideTemplate;
