import { FC, useEffect, useRef, useState } from "react";
import DropDownSubjectSearch from "../forms/inputs/dropDownSubjectSearch";
import { Grid } from "@mui/material";
import CoreDataProbes from "./coreDataProbes";
import CoreDataFormWrapper from "./coreDataFormWrapper";
import { api } from "../../helper/api";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import { t } from "i18next";
import { DotLoader } from "react-spinners";
import Backdrop from "@mui/material/Backdrop";

const CoreData: FC = () => {
  /*eslint-disable */
  const [options, setOptions] = useState<any>([]);
  /*eslint-enable */
  const [subjectId, setSubjectId] = useState<string>("");
  const [allFilteredProbesDetailed, setAllFilteredProbesDetailed] =
    useState<any>([]);
  const [formId, setFormId] = useState<string>("");
  const [formTitle, setFormTitle] = useState<string>("");
  const [coreDataSetForSubject, setCoreDataSetForSubject] = useState<any>([]);
  const [selectedProbeId, setSelectedProbeId] = useState<string>("");
  const [formValues, setFormValues]: any = useState<any>({});
  const [formValuesLoaded, setFormValuesLoaded]: any = useState<boolean>(false);
  const [coreDataId, setCoreDataId] = useState<string>("");
  const [reload, setReload]: any = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  const coreDataRef = useRef(null);

  const resetAsyncInput = () => {
    setSubjectId("");
    setFormId("");
    setFormValuesLoaded(false);
    setSelectedProbeId("");
    setReload(false);
  };

  const onSuccessAndCancelFormHandler = () => {
    // setFilteredProbesByExamination([]);
    setFormId("");
    setFormTitle("");
    setCoreDataSetForSubject([]);
    setSelectedProbeId("");
    setFormValues([]);
    setFormValuesLoaded(false);
    setCoreDataId("");
    setReload(true);
  };
  // search and setSubjectID
  const handleSubjectSelect = (selectedOption: any) => {
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.value
    ) {
      setSubjectId(selectedOption.value);
    }
  };

  useEffect(() => {
    if (subjectId !== "" || reload === true) {
      setIsLoading(true);
      const additionalUrlParameters: any = {};
      const additionalRouteParts: any = {};
      additionalRouteParts["get_or_create"] = "/";
      additionalUrlParameters["subject_id"] = subjectId;
      api.genericApiRequest({
        entity: "coreData",
        method: "get",
        additionalRouteParts: additionalRouteParts,

        successHandler: (res: any) => {
          const data = res.data;
          setCoreDataSetForSubject(data);
          const allProbes = [];
          for (const key in data) {
            const probe = data[key].probe;
            allProbes.push(probe);
          }
          setAllFilteredProbesDetailed(allProbes);
          setIsLoading(false);
        },
        failHandler: (error: any) => {
          setError(error);
          setIsLoading(false);
        },
        parametersToRender: {
          additionalUrlParameters: additionalUrlParameters,
          depth: "1",
        },
      });
    } else {
      setIsLoading(false);
    }
  }, [subjectId, reload]);

  const handleSetFormId = (formId: any, formName: any, probeId: any) => {
    setFormId(formId);
    setFormTitle(formName);
    setSelectedProbeId(probeId);
  };

  // if probe was selected and specific subject has CoreDataSet check if for selected Probes exists values
  // if values exist for a probe of that subject take coreDataId and get metrics of that coreDataId
  // set the values

  useEffect(() => {
    if (
      formId !== "" &&
      coreDataSetForSubject.length !== 0 &&
      selectedProbeId !== ""
    ) {
      const selectedData = coreDataSetForSubject?.find(
        (item: any) => item.probe.id === selectedProbeId
      );

      const additionalUrlParameters: any = {};
      const coreDataId = selectedData.id;
      additionalUrlParameters["coredata_id"] = coreDataId;
      setCoreDataId(coreDataId);
      api.genericApiRequest({
        entity: "metric",
        method: "get",

        successHandler: (res: any) => {
          setFormValues(res.data.results);
          setFormValuesLoaded(true);
        },
        failHandler: (error: any) => {
          setError(error);
        },
        parametersToRender: {
          depth: "0",
          additionalUrlParameters: additionalUrlParameters,
        },
      });
    }
  }, [coreDataSetForSubject, selectedProbeId, formId]);

  const handleGoBack = () => {
    setFormValuesLoaded(false);
    setFormId("");
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <h1>Stammdaten </h1>
        </Grid>
        <Grid item xs={9}>
          <DropDownSubjectSearch
            subdomain={subdomain}
            options={options}
            onChange={handleSubjectSelect}
            resetInput={resetAsyncInput}
            isClearable={true}
            disabled={formId !== "" && formValuesLoaded}
            autofocus={true}
            customRef={coreDataRef}
            useGlobalState={true}
            queryParameter="companies"
          />
        </Grid>
        {isLoading ? (
          <Backdrop
            className="backdrop"
            open={true}
          >
            <DotLoader
              color="#8c1ec8"
              size={65}
              cssOverride={{ position: "absolute", top: "45vh", left: "50vw", zIndex: 1000 }}
            />
          </Backdrop>
        ) : (
          <>
            {allFilteredProbesDetailed.length > 0 &&
              subjectId !== "" &&
              !formValuesLoaded && (
                <CoreDataProbes
                  filteredProbes={allFilteredProbesDetailed}
                  handleSetFormId={handleSetFormId}
                />
              )}
            {formId !== "" && formValuesLoaded && (
              <CoreDataFormWrapper
                formTitle={formTitle}
                formId={formId}
                formValues={formValues}
                handleCancelForm={onSuccessAndCancelFormHandler}
                navigateBack={handleGoBack}
                coreDataId={coreDataId}
                probeId={selectedProbeId}
                subjectId={subjectId}
                onSuccessfulFormSubmitHandler={onSuccessAndCancelFormHandler}
                reload={() => setReload(false)}
              />
            )}
          </>
        )}
      </Grid>
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
};

export default CoreData;
