export function convertEurocentToEuroForLabels(cents: number) {
  const euros = cents / 100;
  return euros.toLocaleString("de-DE", {
    style: "currency",
    currency: "EUR",
  });
}

export const euroToEurocent = (euro: any): number => {
  return Math.round(Number(parseFloat(euro).toFixed(2)) * 100);
};

export const eurocentToEuro = (eurocent: any) => {
  return eurocent / 100;
};
