import React, { FC, useEffect, useState } from "react";
import GenericCrudTable from "../tables/genericCrudTable";
import { t } from "i18next";
import { replaceDate } from "../../helper/dateHandling";
import { useNavigate } from "react-router-dom";
import { convertEurocentToEuroForLabels } from "../../helper/formattingValues";
import EuroIcon from "@mui/icons-material/Euro";
import CheckIcon from "@mui/icons-material/Check";
import { IconButton, Tooltip } from "@mui/material";
import { api } from "../../helper/api";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";

const TrackedServiceMainTable: FC = () => {
  const [refreshTable, setRefreshTable] = useState<boolean>(false);
  const [mainCategoryOptionsForFiltering, setMaincategoryOptionsForFiltering] =
    useState<any[]>([]);
  const [trackedServiceTypeForFiltering, setTrackedServiceTypeForFiltering] =
    useState<any[]>([]);
  const [
    trackedServiceTemplateForFiltering,
    setTrackedServiceTemplateForFiltering,
  ] = useState<any[]>([]);
  const [kaerEmployeeForFiltering, setKaerEmployeeForFiltering] = useState<
    any[]
  >([]);
  const [error, setError] = useState<any>();

  useEffect(() => {
    getMainCategories(setMaincategoryOptionsForFiltering);
    getTrackedServiceType(setTrackedServiceTypeForFiltering);
    getTrackedServiceTemplate(setTrackedServiceTemplateForFiltering);
    getKaerEmployees(setKaerEmployeeForFiltering);
  }, []);

  const navigate = useNavigate();
  const trackedServiceColumns = [
    { header: "ID", accessorKey: "id", id: "id" },
    {
      header: t("company"),
      accessorKey: "company_subject",
      id: "company_subject",
      enableForCreate: true,
      required: true,
      errorMessage: t("field_required"),
      asyncEntity: "subject",
      asyncDropDown: true,
      customColumnFilterId: "company_subject_name",
      customFilterParameter: "__icontains",
      queryParameter: "companies",
      Cell: ({ cell }: any) => {
        return <span>{cell.getValue().name}</span>;
      },
    },
    {
      header: t("subject"),
      accessorKey: "subject.name",
      id: "subject",
      enableForCreate: true,
      asyncEntity: "subject",
      asyncDropDown: true,
      customColumnFilterId: "subject_name",
      customFilterParameter: "__icontains",

      accessorFn: (row: any) => {
        if (row.subject !== null) {
          {
            return row.subject.name;
          }
        } else return "";
      },
    },

    {
      header: t("trackedservice_on_site"),
      accessorKey: "on_site",
      id: "on_site",
      enableForCreate: true,
      checkBox: true,
      filterVariant: "checkbox",
      accessorFn: (row: any) => {
        if (row.on_site !== null) {
          if (row.on_site) {
            return t("yes");
          } else {
            return t("no");
          }
        } else return "-";
      },
    },
    {
      header: t("building_location"),
      accessorKey: "building.name",
      customColumnFilterId: "building__name",
      customFilterParameter: "__icontains",
      id: "building",
      accessorFn: (row: any) => {
        if (row.building !== null) {
          {
            return row.building.name;
          }
        } else return "";
      },
    },
    {
      header: t("description"),
      accessorKey: "description",
      id: "description",
      enableForCreate: true,
      textarea: true,
    },
    {
      header: t("trackedservice_estimated_duration"),
      accessorKey: "estimated_duration",
      id: "estimated_duration",
      enableForCreate: true,
      textfieldType: "number",
      required: true,
      errorMessage: t("field_required"),
    },
    {
      header: t("trackedservice_actual_duration"),
      accessorKey: "actual_duration",
      id: "actual_duration",
      enableForCreate: true,
      textfieldType: "number",
      required: true,
      errorMessage: t("field_required"),
    },
    {
      header: t("price_with_currency", { currencySymbol: "€" }),
      accessorKey: "price",
      id: "price",
      filterVariant: "price",
      customColumnFilterId: "price",
      enableForCreate: true,
      textfieldType: "number",
      required: true,
      errorMessage: t("field_required"),
      Cell: ({ cell }: any) => {
        return <span>{convertEurocentToEuroForLabels(cell.getValue())}</span>;
      },
    },
    {
      header: t("category"),
      accessorKey: "category",
      id: "category",
      enableForCreate: true,
      entity: "mainCategory",
      filterVariant: "select",
      filterSelectOptions: mainCategoryOptionsForFiltering,
      required: true,
      errorMessage: t("field_required"),
      Cell: ({ cell }: any) => {
        return <span>{cell.getValue().name}</span>;
      },
    },
    {
      header: t("trackedservice_type"),
      accessorKey: "tracked_service_type",
      id: "tracked_service_type",
      filterVariant: "select",
      filterSelectOptions: trackedServiceTypeForFiltering,
      enableForCreate: true,
      entity: "trackedServiceType",
      required: true,
      errorMessage: t("field_required"),
      Cell: ({ cell }: any) => {
        return <span>{cell.getValue().name}</span>;
      },
    },
    {
      header: t("trackedservice_template"),
      accessorKey: "tracked_service_template",
      id: "tracked_service_template",
      entity: "trackedServiceTemplate",
      filterVariant: "select",
      filterSelectOptions: trackedServiceTemplateForFiltering,
      Cell: ({ cell }: any) => {
        return <span>{cell.getValue().name}</span>;
      },
      enableForCreate: true,
      required: true,
      errorMessage: t("field_required"),
    },
    {
      header: t("trackedservice_info_finance"),
      accessorKey: "info_finance",
      id: "info_finance",
      enableForCreate: true,
      textarea: true,
    },
    {
      header: t("trackedservice_info_ops"),
      accessorKey: "info_ops",
      id: "info_ops",
      enableForCreate: true,
      textarea: true,
    },
    {
      header: t("trackedservice_billed_on"),
      accessorKey: "billed_on",
      id: "billed_on",
      filterVariant: "date",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => {
        return <span>{replaceDate(cell.getValue())}</span>;
      },
    },
    {
      header: t("trackedservice_billed_by"),
      accessorKey: "billed_by",
      customColumnFilterId: "billed_by",
      enableForCreate: true,
      entity: "user",
      filterVariant: "select",

      filterSelectOptions: kaerEmployeeForFiltering,
      getOnlyActiveUsers: {
        status: true,
        companySubjectId: "11111111-1111-1111-1111-444444444444",
      },
      id: "billed_by",
      accessorFn: (row: any) => {
        if (row.billed_by && row.billed_by !== null) {
          return row.billed_by.name;
        } else return "-";
      },
    },
    {
      header: t("trackedservice_checked_on"),
      accessorKey: "checked_on",
      id: "checked_on",
      filterVariant: "date",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => {
        return <span>{replaceDate(cell.getValue())}</span>;
      },
    },
    {
      header: t("trackedservice_checked_by"),
      accessorKey: "checked_by",
      customColumnFilterId: "checked_by",
      id: "checked_by",
      entity: "user",

      filterVariant: "select",
      filterSelectOptions: kaerEmployeeForFiltering,
      enableForCreate: true,
      getOnlyActiveUsers: {
        status: true,
        companySubjectId: "11111111-1111-1111-1111-444444444444",
      },
      accessorFn: (row: any) => {
        if (row.checked_by && row.checked_by !== null) {
          return row.checked_by.name;
        } else return "-";
      },
    },
    {
      header: t("executed_on"),
      accessorKey: "executed_on",
      id: "executed_on",
      filterVariant: "date",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => {
        return <span>{replaceDate(cell.getValue())}</span>;
      },
    },
    {
      header: t("executed_by"),
      id: "executed_by",
      customColumnFilterId: "executed_by",
      filterVariant: "select",
      filterSelectOptions: kaerEmployeeForFiltering,
      accessorKey: "executed_by",
      accessorFn: (row: any) => {
        if (row.executed_by && row.executed_by !== null) {
          return row.executed_by.name;
        } else return "-";
      },
    },
  ];

  const setTrackedServiceBilled = (row: any, skipQuestion = false) => {
    if (!skipQuestion) {
      if (
        /*eslint-disable */
        !confirm(
          t("trackedservice_setbilled_comfirm", {
            title: `${row.company_subject.name} - ${row.category.name}`,
          })
        )
        /*eslint-enable */
      ) {
        return;
      }
    }

    const additionalRouteParts: any = {};
    additionalRouteParts["mark_billed"] = "/";

    api.genericApiRequest({
      method: "put",
      entity: "trackedService",
      entityId: row.id,
      additionalRouteParts: additionalRouteParts,
      successHandler: () => {
        setRefreshTable(true);
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  };
  const setTrackedServiceChecked = (row: any, skipQuestion = false) => {
    if (!skipQuestion) {
      if (
        /*eslint-disable */
        !confirm(
          t("trackedservice_setchecked_comfirm", {
            title: `${row.company_subject.name} - ${row.category.name}`,
          })
        )
        /*eslint-enable */
      ) {
        return;
      }
    }
    const additionalRouteParts: any = {};
    additionalRouteParts["mark_checked"] = "/";
    api.genericApiRequest({
      method: "put",
      entity: "trackedService",
      entityId: row.id,
      additionalRouteParts: additionalRouteParts,
      successHandler: () => {
        setRefreshTable(true);
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  };

  const customRowActions = (row: any) => {
    const shouldRenderBilledIcon =
      row.original?.checked_on != null && row.original?.billed_on == null;

    const shouldRenderCheckedIcon = row.original?.checked_on == null;

    return (
      <>
        {shouldRenderBilledIcon && (
          <Tooltip
            arrow
            placement="right"
            title={t("trackedservice_setbilled")}
          >
            <span>
              <IconButton onClick={() => setTrackedServiceBilled(row.original)}>
                <EuroIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {shouldRenderCheckedIcon && (
          <Tooltip
            arrow
            placement="right"
            title={t("trackedservice_setchecked")}
          >
            <span>
              <IconButton
                onClick={() => setTrackedServiceChecked(row.original)}
              >
                <CheckIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </>
    );
  };
  const customColumnVisibility = { checked_on: false, billed_on: false };

  return (
    <>
      <GenericCrudTable
        heading={t("trackedservice_recorded")}
        refreshTable={refreshTable}
        entity="trackedService"
        columns={trackedServiceColumns}
        customRowActions={customRowActions}
        customColumnVisibility={customColumnVisibility}
        showColumnFilterbyDefault={true}
        allowColumnFiltering={true}
        customEditHandler={(row: any) => {
          navigate(`/trackedservice/editTrackedService/${row.original.id}`);
        }}
        customCreateHandler={() => {
          navigate(`/trackedservice/createTrackedService`);
        }}
      />
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
};

export default TrackedServiceMainTable;

function getMainCategories(setOptions: any) {
  api.genericApiRequest({
    method: "get",
    entity: "mainCategory",
    successHandler: (res: any) => {
      const categoryOptions = res.data.results.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      setOptions(categoryOptions);
    },
  });
}
function getTrackedServiceType(setOptions: any) {
  api.genericApiRequest({
    method: "get",
    entity: "trackedServiceType",
    successHandler: (res: any) => {
      const typeOptions = res.data.results.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      setOptions(typeOptions);
    },
  });
}
function getTrackedServiceTemplate(setOptions: any) {
  api.genericApiRequest({
    method: "get",
    entity: "trackedServiceTemplate",
    successHandler: (res: any) => {
      const templateOptions = res.data.results.map((item: any) => ({
        value: item.id,
        label: item.internal_name,
      }));
      setOptions(templateOptions);
    },
  });
}
function getKaerEmployees(setOptions: any) {
  const additionalUserParameters: any = {};
  additionalUserParameters["impersonate_subject"] =
    "11111111-1111-1111-1111-444444444444";
  additionalUserParameters["is_active"] = "true";

  api.genericApiRequest({
    entity: "user",
    method: "get",
    parametersToRender: {
      additionalUrlParameters: additionalUserParameters,
      depth: "0",
    },

    successHandler: (res: any) => {
      const employeeOptions = res.data.results.map((item: any) => ({
        value: item.person.subject,
        label: item.last_name + ", " + item.first_name,
      }));

      setOptions(
        employeeOptions.sort((a: any, b: any) => a.label.localeCompare(b.label))
      );
    },
  });
}
