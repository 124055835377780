import React, { FC } from "react";
import GenericCrudTable from "../tables/genericCrudTable";
import { t } from "i18next";
import { replaceDate } from "../../helper/dateHandling";

const TrackedServiceTemplatesTable: FC = () => {
  const trackedServiceTemplateColumns = [
    { header: "ID", accessorKey: "id", id: "id" },
    {
      header: t("internal_name"),
      accessorKey: "internal_name",
      id: "internal_name",
      enableForCreate: true,
      required: true,
      errorMessage: t("field_required"),
    },
    {
      header: t("description"),
      accessorKey: "description",
      id: "description",
      enableForCreate: true,
      textarea: true,
    },
    {
      header: t("estimated_duration"),
      accessorKey: "estimated_duration",
      id: "estimated_duration",
      enableForCreate: true,
      textfieldType: "number",
      required: true,
      errorMessage: t("field_required"),
    },
    {
      header: t("price"),
      accessorKey: "price",
      id: "price",
      enableForCreate: true,
      textfieldType: "number",
      required: true,
      errorMessage: t("field_required"),
    },
    {
      header: t("trackedservice_type"),
      accessorKey: "tracked_service_type",
      id: "tracked_service_type",
      enableForCreate: true,
      entity: "trackedServiceType",
      required: true,
      errorMessage: t("field_required"),
      Cell: ({ cell }: any) => {
        return <span>{cell.getValue().name}</span>;
      },
    },
    {
      header: t("category"),
      accessorKey: "category",
      id: "category",
      enableForCreate: true,
      entity: "mainCategory",
      required: true,
      errorMessage: t("field_required"),
      Cell: ({ cell }: any) => {
        return <span>{cell.getValue().name}</span>;
      },
    },
    {
      header: t("created_on"),
      accessorKey: "created_on",
      id: "created_on",
      filterVariant: "date",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => {
        return <span>{replaceDate(cell.getValue())}</span>;
      },
    },
    {
      header: t("created_by"),
      accessorKey: "created_by",
      customColumnFilterId: "created_by_name",

      id: "created_by",
      Cell: ({ cell }: any) => {
        return <span>{cell.getValue().name}</span>;
      },
    },
    {
      header: t("modified_on"),
      accessorKey: "modified_on",
      filterVariant: "date",

      id: "modified_on",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => {
        return <span>{replaceDate(cell.getValue())}</span>;
      },
    },
    {
      header: t("modified_by"),
      accessorKey: "modified_by",
      customColumnFilterId: "modified_by_name",
      id: "modified_by",
      Cell: ({ cell }: any) => {
        return <span>{cell.getValue().name}</span>;
      },
    },
  ];
  return (
    <>
      <GenericCrudTable
        heading={t("trackedservice_template_tableHeading")}
        entity="trackedServiceTemplate"
        columns={trackedServiceTemplateColumns}
      />{" "}
    </>
  );
};

export default TrackedServiceTemplatesTable;
