import React, { useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import styles from "./preventiveCareList.module.css";
import {
  Box,
  Button,
  Tooltip,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import dayjs from "dayjs";
import { Chip } from "@mui/material";
import { IDropDownOption } from "../../types/DropDown";
import { getTranslatedStatusOptions } from "../../helper/statusOptions";
import GenericCrudTable from "../tables/genericCrudTable";
import DropDownSubjectSearch from "../forms/inputs/dropDownSubjectSearch";
import { replaceDate } from "../../helper/dateHandling";
import PreventiveCareFilterModal from "./preventiveCareFilterModal";
import { api } from "../../helper/api";
import Modal from "../modal";
import FileUploadInput from "../forms/inputs/upload";
import GenericNotification from "../notification/genericNotification";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";

const PreventiveCareMainTable = function PreventiveCareMainTable() {
  /*eslint-disable */
  const [options, setOptions] = useState<IDropDownOption[]>([]);
  /*eslint-enable */
  const [refreshTable, setRefreshTable] = useState<any>(false);

  const [subjectId, setSubjectId] = useState<string>("");
  const [companyId, setCompanyId] = useState<string>("");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [csvUploadModalOpen, setCsvUploadModalOpen] = useState<boolean>(false);
  const [entries, setEntries] = useState({
    created: [],
    updated: [],
    skipped: [],
  });  
  const [entriesModalOpen, setEntriesModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();
  const [filterItemForApiCall, setFilterItemForApiCall] = useState<any>({
    filter: "",
    dateRange: [],
  });
  const [selectedStatus, setSelectedStatus] = useState<any[]>([]);
  const [departmentsForCompany, setDepartmentsForCompany] = useState<any[]>([]);

  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];
  /*eslint-disable */
  const statusFilterOptions = getTranslatedStatusOptions();
  const navigate = useNavigate();
  /*eslint-enable */

  const { t } = useTranslation();
  const appointmentRef = useRef(null);

  const legendItems = [
    {
      color: "#008c7d",
      inActiveColor: "#66bab1",
      label: t("valid"),
      value: "valid",
    },
    {
      color: "#ff6428",
      inActiveColor: "#ffa27e",
      label: t("planned"),
      value: "planned",
    },
    {
      color: "#dc3545",
      inActiveColor: "#ff4154",
      label: t("overdue"),
      value: "overdue",
    },
    {
      color: "#9e9e9e",
      inActiveColor: "#bcbcbc",
      label: t("empty"),
      value: "empty",
    },
  ];

  const handleSubjectSelect = (selectedOption: any) => {
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.value
    ) {
      setSubjectId(selectedOption.value);
      setCompanyId(selectedOption.companyId);
    }
  };
  const refreshParentTable = useCallback(() => {
    setRefreshTable(true);
  }, []);
  const resetAsyncInput = () => {
    setSubjectId("");
  };

  function getUniqueBuildings(data: any) {
    const uniqueBuildings = new Map();
    const result: any = [];

    if (Array.isArray(data.department_set)) {
      data.department_set.forEach((department: any) => {
        const building = department.building;

        if (building && !uniqueBuildings.has(building.id)) {
          uniqueBuildings.set(building.id, building);
          result.push(building);
        }
      });
    }

    return result;
  }

  const getDepartmentsForCompany = () => {
    const additionalUrlParameter: any = {};
    additionalUrlParameter["impersonate_subject"] = subjectId;
    api.genericApiRequest({
      method: "get",
      entity: "department",
      parametersToRender: { additionalUrlParameters: additionalUrlParameter },
      successHandler: (res: any) => {
        const data = res.data.results;
        const tempDepartments = [];
        for (const key in data) {
          const department = data[key];
          tempDepartments.push({
            value: department.value,
            label: department.name,
          });
        }
        setDepartmentsForCompany(tempDepartments);
      },
    });
  };

  const downloadExampleCsv = async () => {
    try {
      const response = await fetch(
        "/api/v1/subjectExaminationDeadline/example_preventive_care_registry_csv/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        const result = await response.json();
        const errorContent = result ? result : t("error_downloading_example_csv");
        throw errorContent;
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = t("example_preventive_care_registry_csv_filename");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setError(error);
      setNotificationMessage(t("download_failed"));
      setNotificationVariant("error");
      setShowNotification(true);
    }
  };

  const handleCsvUpload = async (files: File | File[]) => {
    const filesArray = Array.isArray(files) ? files : [files];

    for (const file of filesArray) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fetch(
          "/api/v1/subjectExaminationDeadline/preventive_care_registry_csv/",
          {
            method: "POST",
            body: formData,
            headers: {
              Accept: "application/json",
            },
          }
        );

        const result = await response.json();

        if (response.ok) {
          setEntries({
            created: result["created entries"] || [],
            updated: result["updated entries"] || [],
            skipped: result["skipped entries"] || [],
          });

          setEntriesModalOpen(true);
          setNotificationMessage(t("upload_successful"));
          setNotificationVariant("success");
          setShowNotification(true);
          setRefreshTable(true);
        } else {
          const errorContent = result ? result : t("csv_upload_error");
          throw errorContent;
        }
      } catch (error) {
        setError(error);
        setNotificationMessage(t("upload_failed"));
        setNotificationVariant("error");
        setShowNotification(true);
      }
    }
  };

  const entriesModalContent = (
    <div className={styles.entriesModalContainer}>
      <section className={styles.entriesSection}>
        <p className={styles.boldText}>{t("created_entries")}:</p>
        <span>{entries?.created?.length || 0}</span>
      </section>
      <section className={styles.entriesSection}>
        <p className={styles.boldText}>{t("updated_entries")}:</p>
        <span>{entries?.updated?.length || 0}</span>
      </section>
      <section className={styles.entriesSectionColumn}>
        <p className={styles.incorrectEntriesText}>{t("incorrect_entries")}:</p>
        <List>
          {entries?.skipped?.map((entry: any, index: number) => (
            <ListItem key={index} className={styles.errorListItem}>
              <ListItemText
                primary={entry?.email || t("unknown_entry")}
                secondary={
                  entry?.fields?.email?.join(", ") ||
                  entry?.reason ||
                  t("unknown_reason")
                }
                secondaryTypographyProps={{ color: "red" }}
              />
            </ListItem>
          ))}
        </List>
      </section>
    </div>
  );        

  useEffect(() => {
    if (subjectId !== "") {
      getDepartmentsForCompany();
    }
  }, [subjectId]);
  const customDetailPanel = (data: any) => {
    const deadlineData = data?.original?.subjectexaminationdeadline_set;
    return (
      <GenericCrudTable
        readOnlyMode={subdomain !== "admin"}
        disableGlobalFilter={true}
        entity={"subjectExaminationDeadline"}
        customTableData={deadlineData}
        additionalRoutePartsForGet={{ preventive_care_registry: "/" }}
        searchId={subjectId}
        refreshTableAfterAction={refreshParentTable}
        customPageSize={"100"}
        additionalUrlParameter={{
          include_draft: "true",
          company_subject_id: subjectId,
        }}
        expandedDepth={true}
        disableAddItem={true}
        columns={[
          { header: "ID", accessorKey: "id", id: "id" },
          {
            header: t("examination"),
            accessorKey: "examination.name",
            id: "examination",
            customColumnFilterId: "examination_name",
            entity: "examination",
            accessorFn: (row: any) => {
              if (row.examination) {
                return row?.examination?.name;
              }
            },
          },

          {
            header: t("last_executed_on"),
            accessorKey: "last_executed_on",
            id: "last_executed_on",
            datePicker: true,
            sortingFn: "datetime",
            required: true,
            errorMessage: t("field_required"),
            filterVariant: "date",
            accessorFn: (row: any) => {
              const lastAppointmentId = row.last_appointment_id;
              const lastExecutedDate = replaceDate(row.last_executed_on);

              let output: any = lastExecutedDate;
              if (
                lastAppointmentId !== null &&
                lastAppointmentId !== undefined
              ) {
                output = (
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.link}
                    to={`/appointmentDashboard/${lastAppointmentId}`}
                  >
                    {lastExecutedDate}
                  </Link>
                );
              }
              return <span>{output}</span>;
            },
          },
          {
            header: t("next_appointment_on"),
            accessorKey: "next_appointment_on",
            id: "next_appointment_on",
            datePicker: true,

            accessorFn: (row: any) => {
              const nextAppointmentId = row.next_appointment_id;
              const nextAppointmentOn = replaceDate(row.next_appointment_on);

              let output: any = nextAppointmentOn;
              if (
                nextAppointmentId !== null &&
                nextAppointmentId !== undefined
              ) {
                output = (
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.link}
                    to={`/appointmentDashboard/${nextAppointmentId}`}
                  >
                    {nextAppointmentOn}
                  </Link>
                );
              }
              return <span>{output}</span>;
            },
          },
          {
            header: t("valid_until"),
            accessorKey: "valid_until",
            id: "valid_until",
            enableForCreate: true,
            datePicker: true,
            Cell: ({ cell }: any) => {
              return <span>{replaceDate(cell.getValue())}</span>;
            },
            sortingFn: "datetime",
            required: true,
            errorMessage: t("field_required"),
            filterVariant: "date",
          },

          {
            header: t("eligibility"),
            accessorKey: "eligibility",
            id: "eligibility",
            size: 255,
            textarea: true,
            enableColumnFilter: false,
            enableForCreate: true,
          },
          {
            header: t("status"),
            accessorKey: "status",
            id: "status",
            Cell: ({ cell }: any) => {
              const value = cell.getValue();

              if (value === "valid") {
                return <span style={{ color: "#008c7d" }}>{t(value)}</span>;
              } else if (value === "planned") {
                return <span style={{ color: "#ff8353" }}>{t(value)}</span>;
              } else if (value === "empty") {
                return <span>{t(value)}</span>;
              }
              return <span style={{ color: "#dc3545" }}>{t("overdue")}</span>;
            },
          },
        ]}
      />
    );
  };

  const chartLegendHeader = () => {
    return (
      <Box display="flex" justifyContent="space-between">
        {legendItems.map((item, index) => (
          <CustomToggleButton
            key={index}
            item={item}
            onButtonClick={handleStatusFilter}
            isSelected={selectedStatus.includes(item.value)}
          />
        ))}
      </Box>
    );
  };

  const handleStatusFilter = (value: any) => {
    if (selectedStatus.includes(value)) {
      setSelectedStatus(selectedStatus.filter((status) => status !== value));
    } else {
      setSelectedStatus([...selectedStatus, value]);
    }
  };
  const handleDateChangeforApiCallItem = (dateRange: any) => {
    setFilterItemForApiCall((prevState: any) => ({
      ...prevState,
      dateRange: dateRange,
    }));
  };
  const handleDropDownChangeForApiCallItem = (filter: any) => {
    setFilterItemForApiCall((prevState: any) => ({
      ...prevState,
      filter: filter,
    }));
  };

  const handleFilterValuesOnModalClose = (
    filter: any,
    updateFilter: any,
    dateRange: any,
    updateDateRange: any
  ) => {
    if (filter !== filterItemForApiCall.filter) {
      updateFilter(filterItemForApiCall.filter);
    }

    if (!areDateRangesEqual(dateRange, filterItemForApiCall.dateRange)) {
      updateDateRange(filterItemForApiCall.dateRange);
    }
    setModalOpen(false);
  };

  const areDateRangesEqual = (dateRange1: any, dateRange2: any) => {
    if (dateRange1.length !== dateRange2.length) {
      return false;
    }

    return dateRange1.every((date: any, index: any) => {
      const otherDate = dateRange2[index];
      if (!date && !otherDate) return true;
      if (!date || !otherDate) return false;

      return date.getTime() === otherDate.getTime();
    });
  };

  const handleCsvExport = () => {
    let href = `/api/v1/subjectExaminationDeadline/export_preventive_care_registry/?company_subject_id=${subjectId}`;

    if (subdomain === "manage") {
      href = `/api/v1/subjectExaminationDeadline/export_preventive_care_registry/`;
    }
    window.location.href = href;
  };

  const handleClearFilter = () => {
    setFilterItemForApiCall({ filter: "", dateRange: [] });
  };

  const checkForShowFilter = () => {
    if (
      filterItemForApiCall.dateRange.length === 2 &&
      filterItemForApiCall.filter !== ""
    ) {
      return true;
    } else return false;
  };

  const customActionButton = () => {
    let tempStartDate;
    let tempEndDate;

    if (filterItemForApiCall.dateRange.length === 2) {
      tempStartDate = new Date(filterItemForApiCall.dateRange[0]).toISOString();
      tempEndDate = new Date(filterItemForApiCall.dateRange[1]).toISOString();
    }

    return (
      <div className={styles.customActionButtonWrapper}>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setModalOpen(true)}
        >
          Filter
        </Button>
        {subdomain === "admin" && (
          <>
            <Button color="secondary" variant="contained" onClick={() => setCsvUploadModalOpen(true)}>
              {t("csv_import")}
            </Button>
            <Button color="secondary" variant="contained" onClick={downloadExampleCsv}>
              {t("csv_import_sample_file")}
            </Button>
          </>
        )}
        <Button color="secondary" variant="contained" onClick={handleCsvExport}>
          {t("csv_export")}
        </Button>
        {checkForShowFilter() && (
          <Chip
            label={`${t(filterItemForApiCall.filter)}: ${dayjs(
              tempStartDate
            ).format("DD.MM.YYYY")} - ${dayjs(tempEndDate).format(
              "DD.MM.YYYY"
            )} `}
            className={styles.chip}
            classes={{ deleteIcon: styles.deleteIcon }}
            onDelete={handleClearFilter}
          ></Chip>
        )}
      </div>
    );
  };

  const additionalUrlParameters: any = {};
  additionalUrlParameters["include_draft"] = true;
  additionalUrlParameters["company_subject_id"] = subjectId;

  if (
    filterItemForApiCall.dateRange.length === 2 &&
    filterItemForApiCall.filter !== ""
  ) {
    const tempStartDate = new Date(
      filterItemForApiCall.dateRange[0]
    ).toISOString();
    const tempEndDate = new Date(
      filterItemForApiCall.dateRange[1]
    ).toISOString();
    additionalUrlParameters[`${filterItemForApiCall.filter}_range`] = [
      dayjs(tempStartDate).format("YYYY-MM-DD"),
      dayjs(tempEndDate).format("YYYY-MM-DD"),
    ];
  }

  if (selectedStatus.length > 0) {
    additionalUrlParameters["status"] = selectedStatus.join(",");
  } else {
    delete additionalUrlParameters["status"];
  }

  const handleModalState = (state: boolean) => {
    setModalOpen(state);
  };

  return (
    <>
      {subdomain === "admin" && (
        <DropDownSubjectSearch
          wrapperClassName={styles.dropDownSubjectSearch}
          options={options}
          onChange={handleSubjectSelect}
          subdomain={subdomain}
          isClearable={true}
          resetInput={resetAsyncInput}
          autofocus={true}
          customRef={appointmentRef}
          useGlobalState={true}
          queryParameter="companies"
          customLabel=""
        />
      )}
      {((subdomain === "admin" && subjectId) ||
        (subdomain !== "admin" && !subjectId)) && (
        <GenericCrudTable
          // customTopToolBarAction={customActions}
          customTopToolBarActionButton={customActionButton()}
          readOnlyMode={subdomain !== "admin"}
          refreshTable={refreshTable}
          showRowActions={false}
          allowColumnFiltering={true}
          showColumnFilterbyDefault={true}
          withSubjectSearch={true}
          heading={t("preventive_care")}
          searchId={subjectId}
          subdomain={subdomain}
          entity={"subjectExaminationDeadline"}
          additionalRoutePartsForGet={{ preventive_care_registry: "/" }}
          additionalUrlParameter={additionalUrlParameters}
          expandedDepth={true}
          customDetailPanel={customDetailPanel}
          additionalKeyForParameteresToRender={"examination_name__icontains"}
          columns={[
            { header: "ID", accessorKey: "id", id: "id" },
            {
              header: t("subject"),
              accessorKey: "person.user.name",
              id: "subject",
              enableForCreate: true,
              asyncEntity: "subject",
              companyIdForFilteringAsync: companyId,
              asyncDropDown: true,
              required: true,
              customColumnFilterId: "subject_name",
              errorMessage: t("field_required"),
              queryParameter: "users",
            },
            {
              header: t("departments"),
              accessorKey: "department_set",
              customColumnFilterId: "department_name",
              customFilterParameter: "__icontains",
              filterVariant: "select",
              filterSelectOptions: departmentsForCompany,
              enableSorting: false,
              id: "department_set",
              accessorFn: (row: any) => {
                const departments: any = [];
                if (row.department_set) {
                  for (const key in row.department_set) {
                    if (row.department_set[key].name) {
                      departments.push(row.department_set[key].name);
                    }
                  }
                }
                return departments.join(", ");
              },
            },
            {
              header: t("building_location"),
              accessorKey: "building",
              id: "building",
              customColumnFilterId: "building_name",
              customFilterParameter: "__icontains",
              accessorFn: (row: any) => {
                const buildings = getUniqueBuildings(row);

                return (
                  <>
                    {buildings.map((building: any) => (
                      <div key={building.id}>
                        <span className={styles.buildingName}>
                          {building.name}
                        </span>
                        <br />
                        <span>
                          {building.street_house}, {building.zip}{" "}
                          {building.city}
                        </span>
                      </div>
                    ))}
                  </>
                );
              },
            },
            {
              header: chartLegendHeader(),
              size: 400,
              enableSorting: false,
              enableColumnFilter: false,
              enableColumnActions: false,
              accessorFn: (row: any) => {
                const barChartValues = [];
                const validObjects = row?.subjectexaminationdeadline_set.filter(
                  (obj: any) => obj.status === "valid"
                ).length;
                const plannedObjects =
                  row?.subjectexaminationdeadline_set.filter(
                    (obj: any) => obj.status === "planned"
                  ).length;
                const overdueObjects =
                  row?.subjectexaminationdeadline_set.filter(
                    (obj: any) => obj.status === "overdue"
                  ).length;
                const noDataObjects =
                  row?.subjectexaminationdeadline_set.filter(
                    (obj: any) => obj.status === "empty"
                  ).length;
                barChartValues.push(
                  validObjects,
                  plannedObjects,
                  overdueObjects,
                  noDataObjects
                );

                const totalExaminations = barChartValues.reduce(
                  (acc, value) => acc + value,
                  0
                );

                return (
                  <div className={styles.stacked_bar_chart}>
                    <div className={styles.stacked_bar}>
                      {barChartValues.map((value, index) => {
                        const widthPercentage =
                          totalExaminations === 0
                            ? 0
                            : (value / totalExaminations) * 100;

                        return (
                          <Tooltip
                            key={index}
                            title={`${legendItems[index].label}: ${value}`}
                            arrow
                            placement="top"
                          >
                            <div
                              key={index}
                              className={styles.bar_segment}
                              style={{
                                width: `${widthPercentage}%`,
                                backgroundColor: legendItems[index].color,
                              }}
                            />
                          </Tooltip>
                        );
                      })}
                    </div>
                  </div>
                );
              },
            },
          ]}
          additionalColumnsForCreate={[
            {
              header: t("examination"),
              accessorKey: "examination.name",
              id: "examination",
              enableForCreate: true,
              entity: "examination",
              required: true,
              customColumnFilterId: "examination_name",
              errorMessage: t("field_required"),
            },
            {
              header: t("executed_on"),
              accessorKey: "executed_on",
              id: "executed_on",
              enableForCreate: true,
              datePicker: true,
              sortingFn: "datetime",
              required: true,
              errorMessage: t("field_required"),
              filterVariant: "date",
            },
            {
              header: t("valid_until"),
              accessorKey: "valid_until",
              id: "valid_until",
              enableForCreate: true,
              datePicker: true,
              sortingFn: "datetime",
              required: true,
              errorMessage: t("field_required"),
              filterVariant: "date",
            },
          ]}
        />
      )}
      {modalOpen && (
        <PreventiveCareFilterModal
          modalOpen={modalOpen}
          handleFilterValuesOnModalClose={handleFilterValuesOnModalClose}
          handleDateChangeForApiCallItem={handleDateChangeforApiCallItem}
          handleDropDownChangeForApiCallItem={
            handleDropDownChangeForApiCallItem
          }
          handleModalState={handleModalState}
          presetValues={filterItemForApiCall}
        />
      )}
      <Modal
        open={csvUploadModalOpen}
        onClose={() => setCsvUploadModalOpen(false)}
        title={t("csv_import")}
        hideDialogActions={true}
      >
        <FileUploadInput
          multiple={false}
          name="preventive_care_csv"
          folderId="preventiveCare"
          onCancel={() => setCsvUploadModalOpen(false)}
          customUploadHandler={handleCsvUpload}
          accept={[".csv"]}
          handleUploadSuccess={(modalOpen: boolean) => {
            if (modalOpen) {
              setCsvUploadModalOpen(false);
            }
          }}
        />
      </Modal>
      <Modal
        open={entriesModalOpen}
        onClose={() => setEntriesModalOpen(false)}
        title={t("overview")}
        cancelButtonText={t("confirm")}
        hideSubmitButton
      >
        {entriesModalContent}
      </Modal>
      {showNotification && (
        <GenericNotification
          message={notificationMessage}
          variant={notificationVariant}
          handleCloseSnackbar={() => setShowNotification(false)}
        />
      )}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => setError("")}
        />
      )}
    </>
  );
};

const CustomToggleButton = ({ item, isSelected, onButtonClick }: any) => {
  return (
    <Box display="flex" alignItems="center" mr={2}>
      <Button
        onClick={() => onButtonClick(item.value)}
        name={item.value}
        sx={{
          width: 20,
          height: 20,
          minWidth: 0,
          backgroundColor: isSelected ? item.color : "",
          borderRadius: "0.5rem",
          border: !isSelected ? `2px solid ${item.color}` : "",
          marginRight: 1,
          "&:hover": {
            backgroundColor: isSelected ? item.color : item.inActiveColor,
            opacity: 0.8,
          },
        }}
      />
      <Typography variant="body2">{item.label}</Typography>
    </Box>
  );
};

export default React.memo(PreventiveCareMainTable);
