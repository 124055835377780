import React, { FC } from "react";
import GenericCrudTable from "../tables/genericCrudTable";
import { t } from "i18next";
import { replaceDate } from "../../helper/dateHandling";

const TrackedServiceTypeTable: FC = () => {
  const trackedServiceTypeTableColumns = [
    { header: "ID", accessorKey: "id", id: "id" },
    {
      header: t("name"),
      accessorKey: "name",
      id: "name",
      enableForCreate: true,
      required: true,
      errorMessage: t("field_required"),
    },

    {
      header: t("created_on"),
      accessorKey: "created_on",
      id: "created_on",
      filterVariant: "date",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => {
        return <span>{replaceDate(cell.getValue())}</span>;
      },
    },
    {
      header: t("created_by"),
      accessorKey: "created_by",
      customColumnFilterId: "created_by_name",

      id: "created_by",
      Cell: ({ cell }: any) => {
        return <span>{cell.getValue().name}</span>;
      },
    },
    {
      header: t("modified_on"),
      accessorKey: "modified_on",
      filterVariant: "date",

      id: "modified_on",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => {
        return <span>{replaceDate(cell.getValue())}</span>;
      },
    },
    {
      header: t("modified_by"),
      accessorKey: "modified_by",
      customColumnFilterId: "modified_by_name",
      id: "modified_by",
      Cell: ({ cell }: any) => {
        return <span>{cell.getValue().name}</span>;
      },
    },
  ];

  return (
    <>
      <GenericCrudTable
        heading={t("trackedservice_types_tableheading")}
        entity="trackedServiceType"
        columns={trackedServiceTypeTableColumns}
      />
    </>
  );
};

export default TrackedServiceTypeTable;
