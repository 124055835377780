import { useEffect, useState } from "react";
import { MenuPlacement, SelectInstance } from "react-select";
import { IDropDownOption } from "../../../types/DropDown";
import { IFolderTreeOptions } from "../../files/folderTree";
import CreatableSelect from "react-select/creatable";
import React from "react";
interface Props {
  options: IDropDownOption[] | IFolderTreeOptions[];
  defaultValue?: any;
  isMulti?: any;
  onChange?: (
    values:
      | IDropDownOption
      | IFolderTreeOptions
      | IDropDownOption[]
      | IFolderTreeOptions[],
    label?: any
  ) => void;
  style?: React.CSSProperties;
  mandatory?: boolean;
  label?: string;
  onBlur?: any;
  isClearable?: boolean;
  isSearchable?: boolean;
  disabled?: boolean;
  name?: string;
  required?: boolean;
  deleteItem?: boolean;
  newTemplateCreated?: boolean;
  editedItem?: any;
  menuPlacement?: MenuPlacement;
  placeholder?: any;
  resetInput?: any;
  def?: any;
  error?: any;
  updateOptions?: any;
}
export const DropDownCreatable = React.forwardRef<SelectInstance, Props>(
  function DropDownCreatable(props, ref) {
    const [values, setValues] = useState<
      | IDropDownOption[]
      | IFolderTreeOptions[]
      | IDropDownOption
      | IFolderTreeOptions
    >([]);
    const [defaultValuesAreSet, setDefaultValuesAreSet] =
      useState<boolean>(false);

    const findOptionForValue = (value: any) => {
      for (const key in props.options) {
        const option = props.options[key];

        if (value == option.value) {
          return option;
        }
      }
    };
    if (
      defaultValuesAreSet ||
      props.options.length == 0 ||
      props.defaultValue == undefined
    ) {
      //nothing to see here...
    } else {
      const preSelectedValue: any = [];
      if (
        props.isMulti &&
        props.defaultValue &&
        Array.isArray(props.defaultValue)
      ) {
        for (const key in props.defaultValue) {
          const option = findOptionForValue(props.defaultValue[key]);
          if (option) {
            preSelectedValue.push(option);
          }
        }

        setDefaultValuesAreSet(true);
        setValues(preSelectedValue);
      } else if (props.defaultValue && props.defaultValue !== undefined) {
        const option = findOptionForValue(props.defaultValue);

        if (option) {
          preSelectedValue.push(option);
        }

        setDefaultValuesAreSet(true);
        setValues(preSelectedValue);
      }
    }

    useEffect(() => {
      if (props.onChange) {
        if (!props.isMulti && Array.isArray(values)) {
          props.onChange(values[0], props.name);
        } else {
          props.onChange(values, props.name);
        }
      }
    }, [values]);

    const onChangeHandler = (selectedOption: any, action?: any) => {
      setValues(selectedOption);
      if (action.action === "clear" && props.resetInput) {
        props.resetInput();
      }
    };
    const createOption = (label: string) => ({
      label,
      value: label.toLowerCase().replace(/\W/g, ""),
    });

    const handleCreateOption = (inputValue: any) => {
      const newOption = createOption(inputValue);
      setValues(newOption);
      if (props.updateOptions) {
        props.updateOptions(newOption);
      }
    };

    useEffect(() => {
      if (props.editedItem === true) {
        setDefaultValuesAreSet(false);
      }
    }, [props.editedItem, values]);

    useEffect(() => {
      if (props.deleteItem === true) {
        setValues([]);
      }
    }, [props.deleteItem]);

    return (
      <label style={{ ...props.style, width: "100%" }}>
        {props.mandatory === true ? `${props.label + "\u{002A}"}` : props.label}
        <CreatableSelect
          ref={ref}
          id={props.name}
          options={props.options}
          value={values}
          onChange={(selectedOption, action) =>
            onChangeHandler(selectedOption, action)
          }
          onBlur={props.onBlur}
          name={props.name}
          isMulti={props.isMulti}
          isDisabled={props.disabled}
          isSearchable={props.isSearchable}
          isClearable={props.isClearable}
          //   required={props.required}
          isLoading={props.options.length !== 0 ? false : true}
          menuPlacement={props.menuPlacement}
          placeholder={props.placeholder}
          onCreateOption={handleCreateOption}
          menuPortalTarget={document.body}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              borderColor: !props.error ? "grey" : "red",
              width: "100%",
            }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
        />
      </label>
    );
  }
);
