interface Props {
  color?: string;
  width?: string;
  height?: string;
  icon:
    | "admin"
    | "appointments"
    | "appointmentDashboard"
    | "auditLog"
    | "bookedAppointments"
    | "building"
    | "company"
    | "coreData"
    | "document"
    | "employee"
    | "examinationList"
    | "folder"
    | "home"
    | "mail"
    | "mainCategory"
    | "measurementDefinition"
    | "newAppointment"
    | "permissions"
    | "permissionGroups"
    | "preventiveCare"
    | "probe"
    | "rules"
    | "subjectCompanyOverview"
    | "teams"
    | "template"
    | "todo"
    | "todoCreator"
    | "todoCrudTable"
    | "settings"
    | "logout"
    | "trackedService"
    | "trackedServiceTypes"
    | "trackedServiceTemplates"
    | "trackedServiceExaminationTemplates"
    | "actionChain";
}

export const KaerIcon = (props: Props) => {
  let width = "30";
  let height = "29";
  let color = "#201E1E";

  if (props.color) {
    color = props.color;
  }

  if (props.width) {
    width = props.width;
  }

  if (props.height) {
    height = props.height;
  }

  const xmlns = "http://www.w3.org/2000/svg";
  const noFill = "none";
  const viewBox = "0 0 24 24";
  const strokeWidth = "2";
  const strokeLinecap = "round";
  const strokeLinejoin = "round";

  let icon = <></>;

  switch (props.icon) {
    case "admin":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M8 10V8c0-2.761 1.239-5 4-5s4 2.239 4 5v2M3.5 17.8v-4.6c0-1.12 0-1.68.218-2.107a2 2 0 0 1 .874-.875c.428-.217.988-.217 2.108-.217h10.6c1.12 0 1.68 0 2.108.217a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108v4.6c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874C18.98 21 18.42 21 17.3 21H6.7c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C3.5 19.481 3.5 18.921 3.5 17.8" />
        </svg>
      );
      break;
    case "appointments":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M16.5 5V3m-9 2V3M3.25 8h17.5M3 10.044c0-2.115 0-3.173.436-3.981a3.896 3.896 0 0 1 1.748-1.651C6.04 4 7.16 4 9.4 4h5.2c2.24 0 3.36 0 4.216.412.753.362 1.364.94 1.748 1.65.436.81.436 1.868.436 3.983v4.912c0 2.115 0 3.173-.436 3.981a3.896 3.896 0 0 1-1.748 1.651C17.96 21 16.84 21 14.6 21H9.4c-2.24 0-3.36 0-4.216-.412a3.896 3.896 0 0 1-1.748-1.65C3 18.128 3 17.07 3 14.955z" />
        </svg>
      );
      break;
    case "appointmentDashboard":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M9 3.5v17m11.5-11h-17M3 9.4c0-2.24 0-3.36.436-4.216a4 4 0 0 1 1.748-1.748C6.04 3 7.16 3 9.4 3h5.2c2.24 0 3.36 0 4.216.436a4 4 0 0 1 1.748 1.748C21 6.04 21 7.16 21 9.4v5.2c0 2.24 0 3.36-.436 4.216a4 4 0 0 1-1.748 1.748C17.96 21 16.84 21 14.6 21H9.4c-2.24 0-3.36 0-4.216-.436a4 4 0 0 1-1.748-1.748C3 17.96 3 16.84 3 14.6z" />
        </svg>
      );
      break;
    case "auditLog":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M9 5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2H9z" />
          <path d="M9 5H7a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-2" />
        </svg>
      );
      break;
    case "bookedAppointments":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M16.5 5V3m-9 2V3M3.25 8h17.5m-10.492 6.242 1.034 1.181c.095.109.266.1.35-.016l2.1-2.907" />
          <path d="M3 10.044c0-2.115 0-3.173.436-3.981a3.896 3.896 0 0 1 1.748-1.651C6.04 4 7.16 4 9.4 4h5.2c2.24 0 3.36 0 4.216.412.753.362 1.364.94 1.748 1.65.436.81.436 1.868.436 3.983v4.912c0 2.115 0 3.173-.436 3.981a3.896 3.896 0 0 1-1.748 1.651C17.96 21 16.84 21 14.6 21H9.4c-2.24 0-3.36 0-4.216-.412a3.896 3.896 0 0 1-1.748-1.65C3 18.128 3 17.07 3 14.955z" />
        </svg>
      );
      break;
    case "building":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M14.7 2c1.68 0 2.52 0 3.162.34a3.058 3.058 0 0 1 1.311 1.359c.327.665.327 1.536.327 3.279v10.044c0 1.743 0 2.614-.327 3.28a3.058 3.058 0 0 1-1.311 1.359C17.22 22 16.38 22 14.7 22H9.3c-1.68 0-2.52 0-3.162-.34a3.058 3.058 0 0 1-1.311-1.359c-.327-.665-.327-1.536-.327-3.279V6.978c0-1.743 0-2.614.327-3.28A3.058 3.058 0 0 1 6.138 2.34C6.78 2 7.62 2 9.3 2z" />
          <path d="M9.5 21v-5.5a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2V21M10 6H8m2 4H8m8-4h-2m2 4h-2" />
        </svg>
      );
      break;
    case "company":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M2.25 22h19.5M4 22V6.978c0-1.743 0-2.614.327-3.28A3.058 3.058 0 0 1 5.638 2.34C6.28 2 7.12 2 8.8 2h.4c1.68 0 2.52 0 3.162.34a3.058 3.058 0 0 1 1.311 1.359C14 4.364 14 5.235 14 6.978V22M10 6H8m2 4H8m2 4H8m11.8 8V11.444c0-2.305-.798-3.148-2.925-3.148H14" />
        </svg>
      );
      break;
    case "coreData":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M12 3C7.582 3 4 4.29 4 5.88c0 4.16 16 4.16 16 0C20 4.29 16.418 3 12 3m8 8.75c0 4.667-16 4.667-16 0" />
          <path d="M4 6v12.165c0 3.78 16 3.78 16 0V6" />
        </svg>
      );
      break;
    case "document":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M9.478 3H7.25A2.25 2.25 0 0 0 5 5.25v13.5A2.25 2.25 0 0 0 7.25 21h9a2.25 2.25 0 0 0 2.25-2.25V12M9.478 3c1.243 0 2.272 1.007 2.272 2.25V7.5A2.25 2.25 0 0 0 14 9.75h2.25A2.25 2.25 0 0 1 18.5 12M9.478 3c3.69 0 9.022 5.36 9.022 9M9 16.5h6m-6-3h4" />
        </svg>
      );
      break;
    case "employee":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M17 19.5c0-1.657-2.239-3-5-3s-5 1.343-5 3m14-3c0-1.23-1.234-2.287-3-2.75M3 16.5c0-1.23 1.234-2.287 3-2.75m12-4.014a3 3 0 1 0-4-4.472M6 9.736a3 3 0 0 1 4-4.472m2 8.236a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
        </svg>
      );
      break;
    case "examinationList":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M9.5 12a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0m5-5.5-5 3.5m5 7.5-5-3.5m10 4.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0m0-13a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
        </svg>
      );
      break;
    case "folder":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M19 7h-7.34a2 2 0 0 1-1.322-.5l-2.272-2M19 7a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1.745a2 2 0 0 1 1.322.5M19 7a2.5 2.5 0 0 0-2.5-2.5H8.066" />
        </svg>
      );
      break;
    case "home":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M6.133 21C4.955 21 4 20.02 4 18.81v-8.802c0-.665.295-1.295.8-1.71l5.867-4.818a2.09 2.09 0 0 1 2.666 0l5.866 4.818c.506.415.801 1.045.801 1.71v8.802c0 1.21-.955 2.19-2.133 2.19z" />
          <path d="M9.5 21v-5.5a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2V21" />
        </svg>
      );
      break;
    case "mail":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="m2.357 7.714 6.98 4.654c.963.641 1.444.962 1.964 1.087.46.11.939.11 1.398 0 .52-.125 1.001-.446 1.964-1.087l6.98-4.654M7.157 19.5h9.686c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.31-1.311c.328-.642.328-1.482.328-3.162V9.3c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311c-.642-.327-1.482-.327-3.162-.327H7.157c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.31 1.311c-.328.642-.328 1.482-.328 3.162v5.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311c.642.327 1.482.327 3.162.327" />
        </svg>
      );
      break;
    case "mainCategory":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="m8.5 13.25 3.5-3.5 3.5 3.5" />
          <path d="M9.713 3.64c.581-.495.872-.743 1.176-.888a2.577 2.577 0 0 1 2.222 0c.304.145.595.393 1.176.888.599.51 1.207.768 2.007.831.761.061 1.142.092 1.46.204.734.26 1.312.837 1.571 1.572.112.317.143.698.204 1.46.063.8.32 1.407.83 2.006.496.581.744.872.889 1.176.336.703.336 1.52 0 2.222-.145.304-.393.595-.888 1.176a3.306 3.306 0 0 0-.831 2.007c-.061.761-.092 1.142-.204 1.46a2.577 2.577 0 0 1-1.572 1.571c-.317.112-.698.143-1.46.204-.8.063-1.407.32-2.006.83-.581.496-.872.744-1.176.889a2.577 2.577 0 0 1-2.222 0c-.304-.145-.595-.393-1.176-.888a3.306 3.306 0 0 0-2.007-.831c-.761-.061-1.142-.092-1.46-.204a2.577 2.577 0 0 1-1.571-1.572c-.112-.317-.143-.698-.204-1.46a3.305 3.305 0 0 0-.83-2.006c-.496-.581-.744-.872-.89-1.176a2.577 2.577 0 0 1 .001-2.222c.145-.304.393-.595.888-1.176.52-.611.769-1.223.831-2.007.061-.761.092-1.142.204-1.46a2.577 2.577 0 0 1 1.572-1.571c.317-.112.698-.143 1.46-.204a3.305 3.305 0 0 0 2.006-.83" />
        </svg>
      );
      break;
    case "measurementDefinition":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M17 12h-2l-2 5-2-10-2 5H7" />
          <path d="M3 12c0-4.243 0-6.364 1.318-7.682C5.636 3 7.758 3 12 3c4.243 0 6.364 0 7.682 1.318C21 5.636 21 7.758 21 12c0 4.243 0 6.364-1.318 7.682C18.364 21 16.242 21 12 21c-4.243 0-6.364 0-7.682-1.318C3 18.364 3 16.242 3 12" />
        </svg>
      );
      break;
    case "newAppointment":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M16.5 5V3m-9 2V3M3.25 8h17.5M10 14h4m-2-2v4m-9-5.956c0-2.115 0-3.173.436-3.981a3.896 3.896 0 0 1 1.748-1.651C6.04 4 7.16 4 9.4 4h5.2c2.24 0 3.36 0 4.216.412.753.362 1.364.94 1.748 1.65.436.81.436 1.868.436 3.983v4.912c0 2.115 0 3.173-.436 3.981a3.896 3.896 0 0 1-1.748 1.651C17.96 21 16.84 21 14.6 21H9.4c-2.24 0-3.36 0-4.216-.412a3.896 3.896 0 0 1-1.748-1.65C3 18.128 3 17.07 3 14.955z" />
        </svg>
      );
      break;
    case "permissions":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M8 10V8c0-2.761 1.239-5 4-5 2.094 0 3.313 1.288 3.78 3.114M3.5 17.8v-4.6c0-1.12 0-1.68.218-2.107a2 2 0 0 1 .874-.875c.428-.217.988-.217 2.108-.217h10.6c1.12 0 1.68 0 2.108.217a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108v4.6c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874C18.98 21 18.42 21 17.3 21H6.7c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C3.5 19.481 3.5 18.921 3.5 17.8M16 14v3" />
        </svg>
      );
      break;
    case "permissionGroups":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M20.5 15.8V8.2a1.91 1.91 0 0 0-.944-1.645l-6.612-3.8a1.88 1.88 0 0 0-1.888 0l-6.612 3.8A1.895 1.895 0 0 0 3.5 8.2v7.602a1.91 1.91 0 0 0 .944 1.644l6.612 3.8a1.88 1.88 0 0 0 1.888 0l6.612-3.8A1.895 1.895 0 0 0 20.5 15.8" />
          <path d="M14.004 10.947V8.925c0-2.641-4.008-2.491-4.008 0v2.021m-.994 0h5.996c.553 0 1.002.453 1.002 1.011v3.032c0 .558-.449 1.011-1.002 1.011H9.002A1.006 1.006 0 0 1 8 14.99v-3.033c0-.558.449-1.01 1.002-1.01" />
        </svg>
      );
      break;
    case "preventiveCare":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M3.25 13h3.68a2 2 0 0 1 1.664.89l.812 1.22a2 2 0 0 0 1.664.89h1.86a2 2 0 0 0 1.664-.89l.812-1.22A2 2 0 0 1 17.07 13h3.68" />
          <path d="m5.45 4.11-2.162 7.847A8 8 0 0 0 3 14.082V19a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-4.918a8 8 0 0 0-.288-2.125L18.55 4.11A2 2 0 0 0 16.76 3H7.24a2 2 0 0 0-1.79 1.11M9 9.5h6m-4.5-3h3" />
        </svg>
      );
      break;
    case "probe":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="m9.75 11.742 1.039 1.181c.095.109.267.1.351-.016L13.25 10" />
          <path d="M19 11.5a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0m-2.107 5.42 3.08 3.08" />
        </svg>
      );
      break;
    case "rules":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M18 21a3 3 0 1 0 0-6 3 3 0 0 0 0 6m0-6V7.5a2 2 0 0 0-2-2h-2.5" />
          <path d="M14.5 8 12 5.5 14.5 3M6 3a3 3 0 1 0 0 6 3 3 0 0 0 0-6m0 6v7.5a2 2 0 0 0 2 2h2.5" />
          <path d="m9.5 16 2.5 2.5L9.5 21" />
        </svg>
      );
      break;
    case "subjectCompanyOverview":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M10.25 14a1 1 0 0 1-1-1v-2.36L11.5 9l2.25 1.64V13a1 1 0 0 1-1 1z" />
          <path d="M19 11.5a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0m-2.107 5.42 3.08 3.08" />
        </svg>
      );
      break;
    case "teams":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M21 19.75c0-2.09-1.67-5.068-4-5.727m-2 5.727c0-2.651-2.686-6-6-6s-6 3.349-6 6m9-12.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0m3 3a3 3 0 1 0 0-6" />
        </svg>
      );
      break;
    case "template":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M9.533 11.15A1.823 1.823 0 0 0 9 12.438V15h2.578c.483 0 .947-.192 1.289-.534l7.6-7.604a1.822 1.822 0 0 0 0-2.577l-.751-.751a1.822 1.822 0 0 0-2.578 0z" />
          <path d="M21 12c0 4.243 0 6.364-1.318 7.682C18.364 21 16.242 21 12 21c-4.243 0-6.364 0-7.682-1.318C3 18.364 3 16.242 3 12c0-4.243 0-6.364 1.318-7.682C5.636 3 7.758 3 12 3" />
        </svg>
      );
      break;
    case "todo":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M3 9.4c0-2.24 0-3.36.436-4.216a4 4 0 0 1 1.748-1.748C6.04 3 7.16 3 9.4 3h5.2c2.24 0 3.36 0 4.216.436a4 4 0 0 1 1.748 1.748C21 6.04 21 7.16 21 9.4v5.2c0 2.24 0 3.36-.436 4.216a4 4 0 0 1-1.748 1.748C17.96 21 16.84 21 14.6 21H9.4c-2.24 0-3.36 0-4.216-.436a4 4 0 0 1-1.748-1.748C3 17.96 3 16.84 3 14.6z" />
          <path d="m8.667 12.633 1.505 1.721a1 1 0 0 0 1.564-.073L15.333 9.3" />
        </svg>
      );
      break;
    case "todoCreator":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M21 7.86c0-.43-.056-.849-.161-1.246-.092-.349-.522-.432-.776-.177L18.34 8.16a1.767 1.767 0 1 1-2.5-2.5l1.723-1.722c.255-.255.172-.685-.177-.777a4.86 4.86 0 0 0-5.828 6.326c.071.2.031.424-.118.573L3.3 18.2c-.4.4-.4 1.049 0 1.448L4.352 20.7c.4.4 1.047.4 1.447 0l8.14-8.14c.15-.15.374-.19.573-.119A4.86 4.86 0 0 0 21 7.86" />
        </svg>
      );
      break;
    case "todoCrudTable":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="m9 8.806 3.562 3.94a.788.788 0 0 0 1.206-.055L21 3" />
          <path d="M21 12a9 9 0 1 1-9-9" />
        </svg>
      );
      break;
    case "settings":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M10.11 3.9a1 1 0 0 1 .995-.9h1.79a1 1 0 0 1 .995.9l.033.333a7.953 7.953 0 0 1 2.209.915l.259-.212a1 1 0 0 1 1.34.067l1.266 1.266a1 1 0 0 1 .067 1.34l-.212.26c.409.676.72 1.419.915 2.208l.332.033a1 1 0 0 1 .901.995v1.79a1 1 0 0 1-.9.995l-.333.033a7.951 7.951 0 0 1-.915 2.209l.212.259a1 1 0 0 1-.067 1.34l-1.266 1.266a1 1 0 0 1-1.34.067l-.26-.212a7.947 7.947 0 0 1-2.208.915l-.033.332a1 1 0 0 1-.995.901h-1.79a1 1 0 0 1-.995-.9l-.033-.333a7.95 7.95 0 0 1-2.209-.915l-.259.212a1 1 0 0 1-1.34-.067L5.003 17.73a1 1 0 0 1-.067-1.34l.212-.26a7.953 7.953 0 0 1-.915-2.208L3.9 13.89a1 1 0 0 1-.9-.995v-1.79a1 1 0 0 1 .9-.995l.333-.033a7.953 7.953 0 0 1 .915-2.209l-.212-.259a1 1 0 0 1 .067-1.34L6.27 5.003a1 1 0 0 1 1.34-.067l.26.212a7.947 7.947 0 0 1 2.208-.915z" />
          <path d="M14.5 12a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0" />
        </svg>
      );
      break;
    case "logout":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M13.496 21H6.5c-1.105 0-2-1.151-2-2.571V5.57c0-1.419.895-2.57 2-2.57h7M16 15.5l3.5-3.5L16 8.5m-6.5 3.496h10" />
        </svg>
      );
      break;
    case "trackedService":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M3.25 13h3.68a2 2 0 0 1 1.664.89l.812 1.22a2 2 0 0 0 1.664.89h1.86a2 2 0 0 0 1.664-.89l.812-1.22A2 2 0 0 1 17.07 13h3.68" />
          <path d="m5.45 4.11-2.162 7.847A8 8 0 0 0 3 14.082V19a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-4.918a8 8 0 0 0-.288-2.125L18.55 4.11A2 2 0 0 0 16.76 3H7.24a2 2 0 0 0-1.79 1.11" />
          <path d="m10.3 8.742 1.034 1.182c.095.108.266.1.35-.017L13.784 7" />
        </svg>
      );
      break;
    case "trackedServiceTypes":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M8.85 6.15 12 3l3.15 3.15L12 9.3zm5.85 6.3 3.15-3.15L21 12.45l-3.15 3.15zm-5.85 5.4L12 14.7l3.15 3.15L12 21zM3 12l3.15-3.15L9.3 12l-3.15 3.15z" />
        </svg>
      );
      break;
    case "trackedServiceTemplates":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="m12 21 8.131-4.208c.316-.164.474-.245.589-.366a1 1 0 0 0 .226-.373c.054-.159.054-.336.054-.692V7.533M12 21l-8.131-4.208c-.316-.164-.474-.245-.589-.366a1.009 1.009 0 0 1-.226-.373C3 15.894 3 15.716 3 15.359V7.533M12 21v-9.063m9-4.404-9 4.404m9-4.404-8.27-4.28c-.267-.138-.4-.208-.541-.235a.994.994 0 0 0-.378 0c-.14.027-.274.097-.542.235L3 7.533m0 0 9 4.404" />
        </svg>
      );
      break;
    case "trackedServiceExaminationTemplates":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M8.4 15.6h2.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311c.327-.642.327-1.482.327-3.162V8.4m-7.2 7.2v.9m0-.9v-2.4c0-1.68 0-2.52.327-3.162a3 3 0 0 1 1.311-1.311C10.68 8.4 11.52 8.4 13.2 8.4h2.4m-7.2 7.2h-.9m8.1-7.2h.9m-.9 0v-.9M8.4 19.65V21h1.8m5.4 0h-1.8m5.4 0H21v-1.8m0-5.4v1.8m0-5.4V8.4h-1.35M15.6 4.35V3h-1.8M8.4 3h1.8M4.8 3H3v1.8m0 5.4V8.4m0 5.4v1.8h1.35" />
        </svg>
      );
      break;
    case "actionChain":
      icon = (
        <svg
          xmlns={xmlns}
          height={height}
          width={width}
          fill={noFill}
          viewBox={viewBox}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
        >
          <path d="M3 7h5.094c.33 0 .495 0 .643.047.132.042.253.111.357.202.117.103.202.245.372.528l5.068 8.446c.17.284.255.425.372.528.103.09.224.16.356.202.148.047.314.047.644.047H21M15 7h6" />
        </svg>
      );
  }

  return <>{icon}</>;
};
