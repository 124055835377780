import React, { HTMLInputTypeAttribute } from "react";
import InputTooltip, { placement } from "../../general/tooltip";
import styles from "./inputWithRef.module.css";

interface Props {
  label?: string;
  tooltip?: boolean;
  placement?: placement;
  tooltipMessage?: any;
  autoComplete?: string;
  mandatory?: boolean;
  name: string | undefined;
  required?: boolean;
  onChange: (event: any) => void;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  readonly?: boolean;
  changeColor?: boolean;
  onFocus?: () => void;
  onBlur?: (event?: any) => void;
  pattern?: any;
  id?: any;
  style?: any;
  disabled?: any;
  className?: string;
  convertToEuro?: boolean;
  // defaultValue?: string;
  min?: any;
  step?: any;
  value?: any;
}

export const InputWithRef = React.forwardRef<HTMLInputElement, Props>(
  function InputWithRef(props, ref) {
    return (
      <div
        className={`${styles.inputContainer} ${props.className}`}
        style={props.style}
      >
        <label
          className={`${styles.label} ${
            props.disabled ? styles.labelDisabled : ""
          }`}
          htmlFor={props.name}
        >
          {props.mandatory === true || props.required === true ? (
            <>
              {props.label}
              <span style={{ color: "red" }}>*</span>
            </>
          ) : (
            <>{props.label}</>
          )}

          {props.tooltip && (
            <InputTooltip
              tooltipMessage={props.tooltipMessage}
              placement={props.placement}
            />
          )}
        </label>
        <input
          ref={ref}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          className={`${styles.inputField} ${
            props.changeColor ? styles.inputFieldLightGray : ""
          }`}
          id={props.name}
          // required={props.required}
          onChange={props.onChange}
          name={props.name}
          autoComplete={props.autoComplete}
          type={props.type}
          disabled={props.disabled}
          placeholder={props.placeholder}
          readOnly={props.readonly}
          min={props.min}
          step={props.step}
          {...(props.value !== undefined && { value: props.value })}
        />
      </div>
    );
  }
);
