import { t } from "i18next";

const getTranslatedLabelWithFallback = (label: string) => {
  const translation = t(label);
  if (translation === undefined) {
    return label;
  }
  return translation;
};

export const statusArray = [
  { value: "open", label: getTranslatedLabelWithFallback("open") },
  {
    value: ["in_progress", "post_processing_required", "scheduled", "approved"],
    label: getTranslatedLabelWithFallback("status_in_progress"),
  },
  {
    value: ["in_review", "waiting_for_review", "review_preperation_required"],
    label: getTranslatedLabelWithFallback("status_review"),
  },
  {
    value: "completed",
    label: getTranslatedLabelWithFallback("status_completed"),
  },
  {
    value: "canceled",
    label: getTranslatedLabelWithFallback("status_canceled"),
  },
  { value: "missed", label: getTranslatedLabelWithFallback("status_missed") },
  {
    value: "declined",
    label: getTranslatedLabelWithFallback("status_declined"),
  },
  {
    value: "prefilled_by_user",
    label: getTranslatedLabelWithFallback("status_prefilled_by_user"),
  },
];
// -----------------------------------------------------------------------------------------------------

export const statusArrayForAppDashboard = [
  { value: "open", label: getTranslatedLabelWithFallback("open") },
  {
    value: "in_progress",
    label: getTranslatedLabelWithFallback("status_in_progress"),
  },
  {
    value: "in_review",
    label: getTranslatedLabelWithFallback("status_review"),
  },
  {
    value: "completed",
    label: getTranslatedLabelWithFallback("status_completed"),
  },
  {
    value: "canceled",
    label: getTranslatedLabelWithFallback("status_canceled"),
  },
  { value: "missed", label: getTranslatedLabelWithFallback("status_missed") },
  {
    value: "declined",
    label: getTranslatedLabelWithFallback("status_declined"),
  },
  {
    value: "prefilled_by_user",
    label: getTranslatedLabelWithFallback("status_prefilled_by_user"),
  },
];
// -----------------------------------------------------------------------------------------------------
export const allStatusOptions = [
  {
    value: "draft",
    label: t("status_draft") + " (draft)",
    translationKey: "status_draft",
    suffix: " (draft)",
    backgroundColor: "#f7d1c0",
  },
  {
    value: "open",
    label: t("status_open") + " (open)",
    translationKey: "status_open",
    suffix: " (open)",
    backgroundColor: "#FFF",
  },
  {
    value: "review_preperation_required",
    label: t("status_review") + " (review_preperation_required)",
    translationKey: "status_review",
    suffix: " (review_preperation_required)",
    backgroundColor: "#F2F080",
  },
  {
    value: "waiting_for_review",
    label: t("status_review") + " (waiting_for_review)",
    translationKey: "status_review",
    suffix: " (waiting_for_review)",
    backgroundColor: "#F2F080",
  },
  {
    value: "post_processing_required",
    label: t("status_post_processing") + " (post_processing_required)",
    translationKey: "status_post_processing",
    suffix: " (post_processing_required)",
    backgroundColor: "#f7d1c0",
  },
  {
    value: "in_progress",
    label: t("status_in_progress") + "( in_progress)",
    translationKey: "status_in_progress",
    suffix: " (in_progress)",
    backgroundColor: "#f7d1c0",
  },
  {
    value: "scheduled",
    label: t("status_scheduled") + " (scheduled)",
    translationKey: "status_scheduled",
    suffix: " (scheduled)",
    backgroundColor: "#f7d1c0",
  },
  {
    value: "canceled",
    label: t("status_canceled") + " (canceled)",
    translationKey: "status_canceled",
    suffix: " (canceled)",
    backgroundColor: "#f4f2f2",
  },
  {
    value: "completed",
    label: t("status_completed") + " (completed)",
    translationKey: "status_completed",
    suffix: " (completed)",
    backgroundColor: "#b3ddd8",
  },
  {
    value: "in_review",
    label: t("status_review") + " (in_review)",
    translationKey: "status_review",
    suffix: " (in_review)",
    backgroundColor: "#F2F080",
  },
  {
    value: "approved",
    label: t("status_approved") + " (approved)",
    translationKey: "status_approved",
    suffix: " (approved)",
    backgroundColor: "#f7d1c0",
  },
  {
    value: "missed",
    label: t("status_missed") + " (missed)",
    translationKey: "status_missed",
    suffix: " (missed)",
    backgroundColor: "#f4f2f2",
  },
  {
    value: "declined",
    label: t("status_declined") + " (declined)",
    translationKey: "status_declined",
    suffix: " (declined)",
    backgroundColor: "#f4f2f2",
  },
  {
    value: "prefilled_by_user",
    label: t("status_prefilled_by_user") + " (prefilled_by_user)",
    translationKey: "status_prefilled_by_user",
    suffix: " (prefilled_by_user)",
    backgroundColor: "#f7d1c0",
  },
];

export const getStatusLabel = (status: string): string => {
  const statusOption = allStatusOptions.find(
    (option) => option.value === status
  );
  return statusOption
    ? `${t(statusOption.translationKey)}${statusOption.suffix}`
    : "";
};

export const getAppointmentDetails = (status: string) => {
  const statusOption = allStatusOptions.find(
    (option) => option.value === status
  );

  const backgroundColor = statusOption?.backgroundColor ?? "#f7d1c0";
  const appointmentStatus = statusOption
    ? t(statusOption.translationKey)
    : t("status_in_progress");

  return { backgroundColor, appointmentStatus };
};

export const getTranslatedStatusOptions = () => {
  return allStatusOptions.map((option) => ({
    ...option,
    label: t(option.translationKey) + option.suffix,
  }));
};
