import React, { FC, useState } from "react";
import Button from "./button";
import { Box, IconButton, LinearProgress, Typography } from "@mui/material";
import { t } from "i18next";
import { useDropzone } from "react-dropzone";
import styles from "./upload.module.css";
import FileTableIcon from "../../files/fileTableIcons";
import { Divider, List, ListItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { api } from "../../../helper/api";
import { store } from "../../state/store";
import FileErrorModal from "../errorHandling/fileErrorModal";
import GenericNotification from "../../notification/genericNotification";
import classNames from "classnames";
import GenericErrorMessageModal from "../errorHandling/genericErrorMessageModal";

interface Props {
  name?: string;
  label?: string;
  entity?: string;
  onCancel?: any;
  handleUploadSuccess?: (modalOpen: boolean) => void;
  multiple: boolean;
  target_filename?: any;
  subjectId?: any;
  folderId?: any;
  companyId?: any;
  evkUpload?: boolean;
  customUploadHandler?: (files: File[]) => Promise<void>;
  accept?: string[];
}

const FileUploadInput: FC<Props> = ({
  /*eslint-disable */
  name,
  label,
  entity,
  onCancel,
  handleUploadSuccess,
  multiple,
  target_filename,
  subjectId,
  folderId,
  companyId,
  evkUpload,
  customUploadHandler,
  accept,
}) => {
  // eslint-disable-next-line
  const [uploading, setUploading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [errorResponse, setErrorResponse] = useState<any>([]);
  const [filesToUpload, setFilesToUpload] = useState<any>([]);
  const [progress, setProgress] = useState<any>();
  // eslint-disable-next-line
  const [uploadCount, setUploadcount] = useState<number>(0);
  const [uploadDone, setUploadDone] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    fileRejections,
  } = useDropzone({
    maxSize: 20000000,
    multiple: multiple,
    onDropAccepted(files) {
      if (multiple) {
        addFilesToUpload(files);
      } else {
        setFilesToUpload([files[0]]);
      }
    },
    accept: accept
      ? accept.reduce((acc: { [key: string]: string[] }, type) => {
        acc[`application/${type.replace('.', '')}`] = [type];
        return acc;
      }, {})
      : {
        "application/msword": [".doc"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [".docx"],
        "image/jpeg": [".jpg", ".jpeg"],
        "image/png": [".png"],
        "application/pdf": [".pdf"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
        "text/csv": [".csv"],
      },
  });

  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
    setNotificationMessage("");
  };

  const confirmError = () => {
    setError(false);
  };

  const addFilesToUpload = (files: any) => {
    const filteredItems = files.filter(
      (i: any) => !filesToUpload.some((ix: any) => i.name === ix.name)
    );
    for (const key in filteredItems) {
      const newItems = filteredItems[key];
      setFilesToUpload((prev: any) => [...prev, newItems]);
    }
  };

  const handleUpload = async () => {
    try {
      setUploading(true);

      if (customUploadHandler) {
        await customUploadHandler(filesToUpload);
        if (handleUploadSuccess) {
          handleUploadSuccess(true);
        }
      } else {
        const promises = filesToUpload.map((file: any) => {
          return new Promise((resolve) => {
            const selectedChildSubjectId: any =
              store.getState()?.parent?.subjectId;

            const formData = new FormData();
            formData.append("file", file);
            formData.append("original_filename", file.name);
            if (target_filename) {
              formData.append("target_file_name", target_filename);
            }
            if (subjectId) {
              formData.append("impersonate_subject", subjectId);
            }
            if (selectedChildSubjectId !== "") {
              formData.append("impersonate_subject", selectedChildSubjectId);
            }
            if (folderId) {
              formData.append("folder_id", folderId);
            }
            if (evkUpload) {
              formData.append("data_collection_agreement", "true");
            }

            const config = {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: (data: any) => {
                setProgress(Math.round((100 * data.loaded) / data.total));
              },
            };

            api.genericApiRequest({
              entity: entity === "user" ? "user/worker_csv" : "file",
              method: "post",
              successHandler: () => {
                setUploadcount((prevstate: number) => prevstate + 1);
                resolve({ file: file.name, status: true });
              },
              failHandler: (error: any) => {
                resolve({
                  file: file.name,
                  status: false,
                  error: error,
                });
              },
              submitData: formData,
              config: config,
            });
          });
        });

        await Promise.all(promises).then((value: any) => {
          const successfulUpload = [];
          const failedUploads = [];
          for (const object of value) {
            if (object.status === false) {
              failedUploads.push(object);
            } else successfulUpload.push(object);
          }

          if (failedUploads.length !== 0) {
            for (const failedObj of failedUploads) {
              setNotificationMessage(t("upload_failed") + " - " + failedObj.file);
              setNotificationVariant("error");
              setShowNotification(true);
            }
            for (const obj of successfulUpload) {
              setUploadDone(true);
              setNotificationMessage(t("upload_successful") + " - " + obj.file);
              setNotificationVariant("success");
              setShowNotification(true);

              if (handleUploadSuccess) {
                handleUploadSuccess(false);
              }
            }
            const combinedErrors = failedUploads.reduce((acc, obj) => {
              const key = obj.file;
              acc[key] = obj.error;
              return acc;
            }, {});
            setErrorResponse(combinedErrors);
            setError(true);
          } else {
            for (const obj of successfulUpload) {
              setUploadDone(true);
              setNotificationMessage(t("upload_successful") + " - " + obj.file);
              setNotificationVariant("success");
              setShowNotification(true);
              if (handleUploadSuccess) {
                handleUploadSuccess(true);
              }
            }
          }
          setUploading(false);
        });
      }
    } catch (error: any) {
      setError(error);
    } finally {
      setUploading(false);
    }
  };

  const styleClasses = classNames(styles.dropzoneBase, {
    [styles.dropzoneFocused]: isFocused,
    [styles.dropzoneAccept]: isDragAccept,
    [styles.dropzoneReject]: isDragReject,
  });

  const errorMessage = [];
  if (fileRejections.length != 0) {
    for (const key in fileRejections) {
      const fileItem = fileRejections[key].file;
      const fileName = fileItem.name;
      const errorItem = fileRejections[key].errors[0];
      const errorCode = errorItem.code;
      let message;

      if (errorCode === "file-too-large") {
        message = t("upload_file_size_too_large");
      }

      errorMessage.push(
        <>
          <ListItem className={styles.errorMessageWrapper} key={fileName}>
            <FileTableIcon color="red" />
            <span className={styles.errorMessageStyle}>
              {fileName} : {message}
            </span>
          </ListItem>
          <Divider component={"li"} />
        </>
      );
    }
  }

  const removeFile = (item: any, i: any) => {
    const filter = filesToUpload.filter((item: any, index: any) => index !== i);
    setFilesToUpload(filter);
  };

  function formatFilesize(filesize: number) {
    const kiloBytes = 1024;
    const megaBytes = kiloBytes * 1024;

    if (filesize < megaBytes) {
      return (filesize / kiloBytes).toFixed(2) + " KB";
    } else {
      return (filesize / megaBytes).toFixed(2) + " MB";
    }
  }

  const files = filesToUpload.map((file: any, index: any) => (
    <React.Fragment key={file.name}>
      <ListItem className={styles.fileListItem} disablePadding>
        <div className={styles.gridContainer}>
          <FileTableIcon />
          <p className="fileListItemName">
            {file.name} - {formatFilesize(file.size)}
          </p>
          <IconButton onClick={() => removeFile(file, index)}>
            <CloseIcon color="error" />
          </IconButton>
        </div>
        <div>
          {progress && (
            <Box className={styles.flexBox}>
              <Box className={styles.flexBoxFullWidth}>
                <LinearProgress variant="determinate" value={progress} />
              </Box>
              <Box className={styles.minWidthBox}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                >{`${Math.round(progress)}%`}</Typography>
              </Box>
            </Box>
          )}
        </div>
      </ListItem>
      <Divider component={ListItem} disablePadding />
    </React.Fragment>
  ));

  return (
    <>
      <section className={styles.uploadWrapper}>
        <div
          {...getRootProps({
            className: styleClasses,
          })}
        >
          <input {...getInputProps()} className={styles.hiddenInput} />
          <p className={styles.uploadInputText}>
            {multiple ? t("upload_description") : t("upload_description_single_file")}
          </p>
          <p className={`${styles.uploadInputText} ${styles.noMargin}`}>
            {accept?.length === 1 ? t("upload_file_type") : t("upload_file_types")}:{" "}
            {accept ? accept.join(", ") : ".doc, .docx, .png, .jpg, .jpeg, .xls, .xlsx, .pdf, .csv"}
          </p>
          <p className={`${styles.uploadInputText} ${styles.noMargin}`}>
            {t("upload_file_size")}: max. 20MB
          </p>
        </div>
        <div className={styles.uploadButtonWrapper}>
          <Button
            title={t("cancel")}
            onClick={() => {
              if (onCancel) {
                onCancel();
              }
            }}
          />
          <Button
            disabled={uploading}
            variant="contained"
            color="secondary"
            title={t("upload")}
            onClick={handleUpload}
          />
        </div>
        {errorMessage.length !== 0 && <List>{errorMessage}</List>}
        {filesToUpload.length !== 0 && (
          <aside>
            <h2 className={styles.fileListHeading}>{t("files")}</h2>
            <List className={styles.fileList}>{files}</List>
          </aside>
        )}
      </section>
      {error && (
        <FileErrorModal
          uploadedFilesValues={errorResponse}
          error={error}
          onErrorConfirm={confirmError}
        />
      )}
      {showNotification && (
        <GenericNotification
          message={notificationMessage}
          variant={notificationVariant}
          handleCloseSnackbar={resetStateOnCloseNotification}
        />
      )}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
};

export default FileUploadInput;

//TODO: Allow Delete on accepted Files - Delete Icon in List - Index
//TODO: If already files were chosen and accepted, let user add files to accepted files instead of selecting all new
//TODO CUSTOMERROR
