import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../helper/api";
import FindingForm from "../forms/findingForm";
import { t } from "i18next";
import { DotLoader } from "react-spinners";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import styles from "./appointmentTreeForm.module.css";

export interface formValues {
  id?: string;
  created_by?: string;
  modified_by?: string;
  created_on?: string;
  modified_on?: string;
  unit?: string;
  name?: string;
  value?: number;
  appointment?: string;
  findings?: [];
}

const AppointmentTreeForm: FC = () => {
  const [formValues, setFormValues] = useState<formValues>();
  const [formName, setFormName] = useState<string>("");
  const [finding, setFinding] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  /*eslint-disable */
  const { appointmentId, formId, findingId } = useParams() as {
    appointmentId: string;
    formId: string;
    findingId: string;
  };
  /*eslint-enable */
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading?.(true);
    if (findingId && findingId !== undefined) {
      const additionalUrlParameters: any = {};
      additionalUrlParameters["finding_id"] = findingId;

      api.genericApiRequest({
        entity: "measurement",
        method: "get",

        successHandler: (res: any) => {
          setFormValues(res.data.results);
          setIsLoading?.(false);
        },
        failHandler: (error: any) => {
          setError?.(error);
        },
        parametersToRender: {
          depth: "0",
          additionalUrlParameters: additionalUrlParameters,
        },
      });
      api.genericApiRequest({
        entity: "finding",
        method: "get",
        entityId: findingId,

        successHandler: (res: any) => {
          setFinding(res.data);
          setIsLoading?.(false);
        },
        failHandler: (error: any) => {
          setError?.(error);
        },
        parametersToRender: {
          depth: "0",
        },
      });
    }
  }, [formId]);
  const readOnly = false;

  const handleNavigateBack = () => {
    navigate("/appointmentDashboard/" + appointmentId);
  };

  return (
    <>
      {isLoading && (
        <DotLoader
          color="#8c1ec8"
          size={65}
          cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
        />
      )}
      {formId && formValues && finding && (
        <>
          <h2 className={styles.formName}>{formName}</h2>
          <FindingForm
            withPaper={true}
            findingId={findingId}
            formId={formId}
            formValues={formValues}
            readOnly={readOnly}
            onSuccessfulFormSubmitHandler={() => {
              handleNavigateBack();
            }}
            onCancelFormHandler={() => {
              handleNavigateBack();
            }}
            onFormTitleChange={(formTitle: any) => {
              setFormName(formTitle);
            }}
          />
        </>
      )}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
};

export default AppointmentTreeForm;
