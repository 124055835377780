import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
const clearAction = createAction("clear");
const clearAppointmentDashboardCombinedFilter = createAction(
  "clearAppointmentDashboardCombinedFilter"
);
interface FiltersState {
  status: string[] | string;
  mainCategory: string;
  executedBy: string;
  subject: { value: string; label: string } | any;
}
const initialState: FiltersState = {
  status: "",
  mainCategory: "",
  executedBy: "",
  subject: { value: "", label: "" },
};

const appointmentDashboardCombinedFilterSlice = createSlice({
  name: "appointmentDashboardCombinedFilterSlice",
  initialState,
  reducers: {
    setAppointmentDashboardCombinedFilter: (
      state: any,
      action: PayloadAction<{ name: keyof FiltersState; value: any }>
    ) => {
      const { name, value } = action.payload;
      if (name === "subject") {
        state.subject = { value: value.value, label: value.label };
      } else {
        state[name] = value;
      }
    },
    resetAppointmentDashboardCombinedFilters: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(clearAction, () => initialState);
    builder.addCase(
      clearAppointmentDashboardCombinedFilter,
      () => initialState
    );
  },
});

export const {
  setAppointmentDashboardCombinedFilter,
  resetAppointmentDashboardCombinedFilters,
} = appointmentDashboardCombinedFilterSlice.actions;
export default appointmentDashboardCombinedFilterSlice.reducer;
