import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import GenericCrudTable from "../tables/genericCrudTable";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { api } from "../../helper/api";
import { useState } from "react";
import { DotLoader } from "react-spinners";
import dayjs from "dayjs";
import DropDownSubjectSearch from "../forms/inputs/dropDownSubjectSearch";
import { IDropDownOption } from "../../types/DropDown";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import CircleIcon from "@mui/icons-material/Circle";
import GenericNotification from "../notification/genericNotification";
import styles from "./appointmentRequestTable.module.css";
import { getTranslatedStatusOptions } from "../../helper/statusOptions";

export function AppointmentRequestTable() {
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [invitedSubjects, setInvitedSubjects] = useState<any>([]);
  /*eslint-disable */
  const [options, setOptions] = useState<IDropDownOption[]>([]);
  /*eslint-enable */
  const [companyId, setCompanyId] = useState<string>("");
  const [error, setError] = useState<any>();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info"
  >();

  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  const statusFilterOptions = getTranslatedStatusOptions();

  const navigate = useNavigate();
  const { t } = useTranslation();
  /*eslint-enable */
  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
  };

  const setAppointmentRequestStatusOpen = (
    row: any,
    skipQuestion: false | undefined
  ) => {
    if (!skipQuestion) {
      if (
        /*eslint-disable */
        !confirm(
          t("set_status_to_open_for_item_question", {
            title: row.original.title,
          })
        )
        /*eslint-enable */
      ) {
        return;
      }
    }
    const result = {
      status: "open",
    };
    const additionalRouteParts: any = {};
    additionalRouteParts["set_status"] = "/";

    setShowSpinner(true);

    api.genericApiRequest({
      entity: "appointmentRequest",
      method: "post",
      successHandler: () => {
        window.location.reload();
      },
      failHandler: (error: any) => {
        setShowSpinner(false);
        setError(error);
      },
      entityId: row.original.id,
      additionalRouteParts: additionalRouteParts,
      parametersToRender: {
        depth: "0",
      },
      submitData: result,
    });
  };

  const reInviteSubjects = (row: any, skipQuestion: false | undefined) => {
    if (!skipQuestion) {
      if (
        /*eslint-disable */
        !confirm(t("reinvite_subjects_question"))
        /*eslint-enable */
      ) {
        return;
      }
    }
    setShowSpinner(true);
    const additionalRouteParts: any = {};
    additionalRouteParts["send_invites_to_new_subjects"] = "/";
    api.genericApiRequest({
      entity: "appointmentRequest",
      method: "put",
      successHandler: () => {
        setShowSpinner(false);
        setNotificationMessage(t("customer_all_new_successfully_added"));
        setShowNotification(true);
        setNotificationVariant("success");
      },
      failHandler: (error: any) => {
        setShowSpinner(false);
        setError(error);
        // setNotificationMessage(error);
        // setNotificationVariant("error");
        // setShowNotification(true);
      },
      entityId: row.original.id,
      additionalRouteParts: additionalRouteParts,
      parametersToRender: {
        depth: "0",
      },
    });
  };
  const openCreateHandler = () => {
    navigate("/appointmentbooking/");
  };

  const openEditHandler = (row: any) => {
    if (row.original.status !== "draft") {
      navigate(`/editappointmentrequest/${row.original.id}` + "/building");
    } else {
      navigate(`/editappointmentrequest/${row.original.id}` + "/settings");
    }
  };

  const openTimeslotEditHandler = (id: any) => {
    navigate(`/editappointmentrequest/${id}` + "/timeslots");
  };
  const replaceDate = (id: any) => {
    const date = new Date(id).toLocaleString();
    return date;
  };

  const handleShowInvitedPersons = (row: any) => {
    api.genericApiRequest({
      entity: "appointmentRequest",
      method: "get",
      successHandler: (res: any) => {
        const tempInvited = [];
        for (const key in res.data.subject_info_set) {
          const subject = res.data.subject_info_set[key];
          tempInvited.push(subject);
          tempInvited.sort((a: any, b: any) => a.name.localeCompare(b.name));
        }
        setInvitedSubjects(tempInvited);
        setModalOpen(true);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: row.original.id,
    });
  };

  const handleSubjectSelect = (selectedOption: any) => {
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.value &&
      selectedOption.companyId
    ) {
      setCompanyId(selectedOption.companyId);
    }
  };

  const resetAsyncInput = () => {
    setCompanyId("");
  };

  return (
    <>
      {showSpinner ? (
        <DotLoader
          color="#8c1ec8"
          size={65}
          cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
        />
      ) : (
        <>
          <DropDownSubjectSearch
            wrapperClassName={styles.dropDownSubjectSearch}
            options={options}
            onChange={handleSubjectSelect}
            subdomain={subdomain}
            isClearable={true}
            resetInput={resetAsyncInput}
            queryParameter="companies"
            customLabel={t("company")}
            useGlobalState={true}
            autofocus={true}
          />
          <GenericCrudTable
            heading={t("appointmentrequest_single")}
            withSubjectSearch={true}
            searchId={companyId}
            entity={"appointmentRequest"}
            allowColumnFiltering={true}
            showColumnFilterbyDefault={true}
            customColumnVisibility={{
              parallel_slots: false,
              created_on: false,
            }}
            columns={[
              { header: "ID", accessorKey: "id", id: "id" },
              {
                header: t("status"),
                accessorKey: "status",
                filterVariant: "select",
                filterSelectOptions: statusFilterOptions,
                id: "status",
                accessorFn: (row: any) => {
                  const status = row.status;
                  const string = "status_" + status;

                  return `${t(string)} (${status})`;
                },
              },
              {
                header: t("company"),
                accessorKey: "name",
                id: "company",
                customColumnFilterId: "company_name",
                accessorFn: (row: any) => {
                  if (row.company) {
                    const name = row.company.name;
                    return name;
                  }
                },
                size: 400,
              },
              {
                header: t("department"),
                accessorKey: "department_set",
                id: "department_set",
                accessorFn: (row: any) => {
                  const departments = [];
                  if (row.department_set) {
                    for (const key in row.department_set) {
                      if (row.department_set[key].name) {
                        departments.push(row.department_set[key].name);
                      }
                    }
                  }
                  return departments.join(", ");
                },
              },
              {
                header: t("category"),
                accessorKey: "category",
                id: "category",
                customColumnFilterId: "category__name",
                accessorFn: (row: any) => {
                  if (row.category) {
                    const name = row.category.name;
                    return name;
                  }
                },
              },
              {
                header: t("name"),
                accessorKey: "title",
                id: "title",
              },
              {
                header: t("date"),
                accessorKey: "day_set",
                id: "day_set",
                customColumnFilterId: "day_set",
                filterVariant: "date",

                accessorFn: (row: any) => {
                  const daysInRange = row.day_set.length;
                  let date;
                  if (row.day_set.length !== 0) {
                    const set = row.day_set;
                    const start = dayjs(set[0].start).format("DD.MM.YYYY");
                    const end = dayjs(set[daysInRange - 1].end).format(
                      "DD.MM.YYYY"
                    );

                    if (start === end) {
                      date = start;
                    } else {
                      date = start + " - " + end;
                    }
                    // }
                    return date;
                  }
                },
                size: 80,
              },
              {
                header: t("parallel_slots"),
                accessorKey: "parallel_slots",
                id: "parallel_slots",
              },
              {
                header: t("duration"),
                accessorKey: "slot_duration",
                id: "slot_duration",
                size: 50,
              },
              {
                header: t("executed_by"),
                id: "executed_by",
                customColumnFilterId: "executed_by_name",

                accessorKey: "executed_by",
                accessorFn: (row: any) => {
                  if (row.executed_by && row.executed_by !== null) {
                    return row.executed_by.name;
                  } else return "-";
                },
              },
              {
                header: t("created_on"),
                accessorKey: "created_on",
                id: "created_on",
                filterVariant: "date",

                sortingFn: "datetime",
                Cell: ({ cell }: any) => {
                  return <span>{replaceDate(cell.getValue())}</span>;
                },
              },
              {
                header: t("created_by"),
                accessorKey: "created_by",
                customColumnFilterId: "created_by_name",
                id: "created_by",
                Cell: ({ cell }: any) => {
                  return <span>{cell.getValue().name}</span>;
                },
              },
              {
                header: t("modified_on"),
                accessorKey: "modified_on",
                id: "modified_on",
                filterVariant: "date",
                sortingFn: "datetime",
                Cell: ({ cell }: any) => {
                  return <span>{replaceDate(cell.getValue())}</span>;
                },
              },
              {
                header: t("modified_by"),
                accessorKey: "modified_by",
                customColumnFilterId: "modified_by_name",
                id: "modified_by",
                Cell: ({ cell }: any) => {
                  return <span>{cell.getValue().name}</span>;
                },
              },
            ]}
            setAppointmentRequestStatusOpen={setAppointmentRequestStatusOpen}
            handleReinviteSubjects={reInviteSubjects}
            customEditHandler={openEditHandler}
            customCreateHandler={openCreateHandler}
            handleShowInvitedPersons={handleShowInvitedPersons}
            handleTimeslotEditHandler={openTimeslotEditHandler}
            additionalUrlParameter={{ include_draft: "true" }}
          />
          {error && error !== "" && (
            <GenericErrorMessageModal
              title={t("error_occurred")}
              error={error}
              onClosehandler={() => {
                setError("");
              }}
            />
          )}
          {showNotification && (
            <GenericNotification
              message={notificationMessage}
              variant={notificationVariant}
              handleCloseSnackbar={resetStateOnCloseNotification}
            />
          )}
        </>
      )}
      <Dialog open={modalOpen} scroll="paper" component={"div"}>
        <DialogTitle className={styles.dialogTitle}>{t("invited_users")}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText className={styles.dialogContentText}>
            <List>
              {invitedSubjects.map((subject: any) => (
                <ListItem key={subject.id} disablePadding>
                  <ListItemIcon>
                    {subject.appointment_created ? (
                      <CircleIcon color="success" fontSize="small" />
                    ) : (
                      <CircleIcon color="error" fontSize="small" />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={subject.name} />
                </ListItem>
              ))}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

