import { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";

import { IDropDownOption } from "../../../types/DropDown";
import { IFolderTreeOptions } from "../../files/folderTree";
import React from "react";
import { SelectInstance } from "react-select";
import { useDispatch } from "react-redux";
interface Props {
  options: IDropDownOption[] | IFolderTreeOptions[];
  preExistingValue?: any;
  isMulti?: any;
  dataCallback: any;
  onChange?: (
    values:
      | IDropDownOption
      | IFolderTreeOptions
      | IDropDownOption[]
      | IFolderTreeOptions[],
    label?: any
  ) => void;
  labelStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  wrapperClassName?: string;
  labelClassName?: string;
  mandatory?: boolean;
  label?: string;
  onBlur?: any;
  onFocus?: any;
  isClearable?: boolean;
  isSearchable?: boolean;
  disabled?: boolean;
  name?: string;
  required?: boolean;
  deleteItem?: boolean;
  newTemplateCreated?: boolean;
  editedItem?: any;
  error?: any;
  resetInput?: any;
  autofocus?: boolean;
  placeholder?: any;
}

export const DropDownAsync = React.forwardRef<SelectInstance, Props>(
  function DropDownAsync(props, ref: any) {
    const [values, setValues] = useState<
      | IDropDownOption[]
      | IFolderTreeOptions[]
      | IDropDownOption
      | IFolderTreeOptions
      | any
    >([]);
    const dispatch = useDispatch();

    const debounce = (fn: any, delay = 250) => {
      let timeout: any;

      return (...args: any) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fn(...args);
        }, delay);
      };
    };

    useEffect(() => {
      if (props.autofocus) {
        ref?.current.focus();
      }
    }, []);

    useEffect(() => {
      if (props.onChange) {
        if (!props.isMulti && Array.isArray(values)) {
          props.onChange(values[0], props.name);
        } else {
          props.onChange(values, props.name);
        }
      }
    }, [values]);

    useEffect(() => {
      if (
        props.preExistingValue !== undefined &&
        props.preExistingValue !== null &&
        Object.keys(props.preExistingValue).length !== 0
      ) {
        setValues(props.preExistingValue);
      }
    }, [props.preExistingValue]);

    const onChangeHandler = (selectedOption: any, action?: any) => {
      setValues(selectedOption);
      if (action.action === "clear" && props.resetInput) {
        props.resetInput();
      }
      if (action.action === "clear") {
        dispatch({ type: "clearSubjectSearch" });
      }
    };

    const loadOptionsDebounced = debounce(
      (inputValue: string, callback: any) => {
        props.dataCallback(inputValue, callback);
      },
      500
    );

    return (
      <div
        className={props.wrapperClassName}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          ...props.wrapperStyle,
        }}
      >
        <label
          htmlFor={props.name}
          className={props.labelClassName}
          style={props.labelStyle}
        >
          {props.mandatory === true ? (
            <>
              {props.label}
              <span style={{ color: "red" }}>*</span>
            </>
          ) : (
            <>{props.label}</>
          )}
        </label>
        <AsyncSelect
          autoFocus={props.autofocus}
          ref={ref}
          id={props.name}
          loadOptions={loadOptionsDebounced}
          value={values}
          onChange={(selectedOption, action) =>
            onChangeHandler(selectedOption, action)
          }
          onBlur={props.onBlur}
          name={props.name}
          isMulti={props.isMulti}
          isDisabled={props.disabled}
          isSearchable={props.isSearchable}
          isClearable={props.isClearable}
          required={props.required}
          placeholder={props.placeholder}
          onFocus={props.onFocus}
          menuPortalTarget={document.body}
          styles={{
            clearIndicator: (base) => ({
              ...base,
              color: "#ba78de",
              backgroundColor: "#f4f2f2",
              borderRadius: "0.75rem",
              marginRight: "0.3rem",
              "&:hover": {
                color: "#8c1ec8",
              },
            }),
            dropdownIndicator: (base) => ({
              ...base,
              color: "#ba78de",
              backgroundColor: "#f4f2f2",
              borderRadius: "0.75rem",
              margin: "0 0.3rem",
              "&:hover": {
                color: "#8c1ec8",
              },
            }),
            loadingIndicator: (base) => ({
              ...base,
              color: "#ba78de",
            }),
            control: (baseStyles) => ({
              ...baseStyles,
              borderRadius: "0.5rem",
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              margin: "0",
              padding: "0",
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              padding: "0.75rem",
              borderRadius: "0.5rem",
            }),
            option: (styles, { isDisabled, isFocused, isSelected }) => {
              return {
                ...styles,
                backgroundColor: isDisabled
                  ? undefined
                  : isSelected
                  ? "#f4f2f2"
                  : isFocused
                  ? "#a34bd3"
                  : undefined,
                color: isDisabled
                  ? undefined
                  : isSelected
                  ? "black"
                  : isFocused
                  ? "white"
                  : undefined,

                cursor: isDisabled ? "not-allowed" : "default",
              };
            },
            menuPortal: (base) => ({
              ...base,
              zIndex: 99999,
            }),
          }}
        />
      </div>
    );
  }
);
