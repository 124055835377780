import ActionChainList from "../../routes/admin/ActionChainList";
import { AppointmentEditor } from "../../routes/admin/appointmentEditor";
import AuditLog from "../../routes/admin/auditLog";
import CompanyList from "../../routes/admin/companyList";
import CoreDataView from "../../routes/admin/coreDataView";
import ExaminationList from "../../routes/admin/examinationList";
import FolderEditor from "../../routes/admin/folderEditor";
import { FormEditor } from "../../routes/admin/formEditor";
import MailTemplateList from "../../routes/admin/mailTemplateList";
import MainCategoryList from "../../routes/admin/mainCategoryList";
import MeasurementDefinitionCreate from "../../routes/admin/measurementDefinitionCreate";
import MeasurementDefinitionList from "../../routes/admin/measurementDefinitionList";
import PermissionGroupList from "../../routes/admin/permissionGroupList";
import PermissionList from "../../routes/admin/permissionList";
import ProbeEditor from "../../routes/admin/probeEditor";
import ProbeList from "../../routes/admin/probeList";
import RuleList from "../../routes/admin/ruleList";
import TeamsList from "../../routes/admin/teamsList";
import { TemplateEditor } from "../../routes/admin/templateEditor";
import TodoCreator from "../../routes/admin/todoCreator";
import TodoList from "../../routes/admin/todoList";
import UserAdministration from "../../routes/userAdministration";
import ActionChainCreateAndEdit from "../actionChain/actionChainCreateAndEdit";
import ActionChainProgress from "../actionChain/actionChainProgress";
import MailTemplateEditor from "../mailTemplate/mailTemplateEditor";
import TrackedServiceCustomCrud from "../trackedService/trackedServiceCustomCrud";
import TrackedServiceExaminationTemplatesTable from "../trackedService/trackedServiceExaminationTable";
import TrackedServiceMainTable from "../trackedService/trackedServiceMainTable";
import TrackedServiceTemplatesTable from "../trackedService/trackedServiceTemplates";
import TrackedServiceTypeTable from "../trackedService/trackedServiceTypeTable";

const adminSubroutes = [
  {
    path: "/admin/userAdministration",
    name: "Mitarbeiter",
    icon: "employee",
    index: 29,
    permission: "frontend_view_user_administration",
  },
  {
    path: "/admin/companyList",
    name: "Firma",
    icon: "company",
    index: 30,
    permission: "frontend_view_company",
  },
  {
    path: "/admin/teams",
    name: "Teams",
    icon: "teams",
    index: 39,
    permission: "frontend_view_team",
  },
  {
    path: "/admin/todoList",
    name: "Todo",
    icon: "todoCrudTable",
    index: 31,
    permission: "frontend_view_todo_table",
  },
  {
    path: "/admin/coreData",
    name: "Stammdaten",
    icon: "coreData",
    index: 35,
    permission: "frontend_view_core_data",
  },
  {
    path: "/admin/todoCreator",
    name: "Todo Creator",
    icon: "todoCreator",
    index: 36,
    permission: "frontend_view_todo_creator",
  },
];
const settingsSoubRoutes = [
  {
    path: "/settings/mainCategoryList",
    name: "Hauptkategorien",
    icon: "mainCategory",
    index: 22,
    permission: "frontend_view_main_category",
  },
  {
    path: "/settings/examinationList",
    name: "Untersuchungsreihen",
    icon: "examinationList",
    index: 23,
    permission: "frontend_view_examination",
  },
  {
    path: "/settings/probeList",
    name: "Einzeluntersuchung",
    icon: "probe",
    index: 24,
    permission: "frontend_view_probe",
  },
  {
    path: "/settings/measurementDefinitionList",
    name: "Messpunktdefinitionen",
    icon: "measurementDefinition",
    index: 25,
    permission: "frontend_view_measurement_definition",
  },
  {
    path: "/settings/templateEditor",
    name: "Template",
    icon: "template",
    index: 27,
    permission: "frontend_view_template",
  },
  {
    path: "/settings/documentEditor",
    name: "Ordner",
    icon: "folder",
    index: 28,
    permission: "frontend_view_folder",
  },
  {
    path: "/settings/ruleList",
    name: "Regeln",
    icon: "rules",
    index: 33,
    permission: "frontend_view_rule",
  },
  {
    path: "/settings/mailTemplateList",
    name: "Email Templates",
    icon: "template",
    index: 34,
    permission: "frontend_view_email_template",
  },
  {
    path: "/settings/permissionList",
    name: "Berechtigungen",
    icon: "permissions",
    index: 37,
    permission: "frontend_view_permission",
  },
  {
    path: "/settings/permissionGroupList",
    name: "Berechtigungen - Gruppen",
    icon: "permissionGroups",
    index: 38,
    permission: "frontend_view_permission_group",
  },
  {
    path: "/settings/auditlog",
    name: "Audit Log",
    icon: "auditLog",
    index: 40,
    permission: "frontend_view_auditlog",
  },
  // {
  //   path: "/settings/actionChains",
  //   name: "Actionchains",
  //   icon: "actionChain",
  //   index: 41,
  //   permission: "frontend_view_action_chains",
  // },
];
const trackedServiceSubRoutes = [
  {
    path: "/trackedservice/recorded",
    name: "Erfasste Leistungen",
    icon: "trackedService",
    index: 5000,
    permission: "view_trackedservice",
  },
  {
    path: "/trackedservice/types",
    name: "Typen",
    icon: "trackedServiceTypes",
    index: 5001,
    permission: "view_trackedservicetype",
  },
  {
    path: "/trackedservice/trackedServiceTemplates",
    name: "Templates - Leistungen",
    icon: "trackedServiceTemplates",
    index: 5002,
    permission: "view_trackedservicetemplate",
  },
  {
    path: "/trackedservice/examinationTemplates",
    name: "Templates - Examinations",
    icon: "trackedServiceExaminationTemplates",
    index: 5003,
    permission: "view_examinationtrackedservicetemplate",
  },
];
const trackedServiceSubRoutContent = [
  {
    path: "recorded",
    name: "Erfasste Leistungen",
    icon: "trackedService",
    element: <TrackedServiceMainTable />,
  },
  {
    path: "types",
    name: "Typen",
    icon: "trackedServiceTypes",
    element: <TrackedServiceTypeTable />,
  },
  {
    path: "trackedServiceTemplates",
    name: "Templates - Leistungen",
    icon: "trackedServiceTemplates",
    element: <TrackedServiceTemplatesTable />,
  },
  {
    path: "examinationTemplates",
    name: "Templates - Examinations",
    icon: "trackedServiceExaminationTemplates",
    element: <TrackedServiceExaminationTemplatesTable />,
  },
  {
    path: "createTrackedService",
    element: <TrackedServiceCustomCrud />,
  },
  {
    path: "editTrackedService/:trackedServiceId?",
    element: <TrackedServiceCustomCrud />,
  },
];

const settingsSubrouteContent = [
  {
    path: "mainCategoryList",
    name: "Hauptkategorien",
    element: <MainCategoryList />,
    icon: "",
  },
  {
    path: "examinationList",
    element: <ExaminationList />,
    name: "Untersuchungsreihen",
    icon: "",
  },
  {
    path: "probeList",
    element: <ProbeList />,
    name: "Einzeluntersuchung",
    icon: "",
  },
  {
    path: "measurementDefinitionList",
    element: <MeasurementDefinitionList />,
    name: "Messpunktdefinitionen",
    icon: "",
  },

  {
    path: "templateEditor",
    element: <TemplateEditor />,
    name: "Template",
    icon: "",
  },
  {
    path: "documentEditor",
    element: <FolderEditor />,
    name: "Ordner",
    icon: "",
  },
  {
    path: "ruleList",
    element: <RuleList />,
    name: "Regeln",
    icon: "",
  },
  {
    path: "mailTemplateList",
    element: <MailTemplateList />,
    name: "Email Templates",
    icon: "",
  },
  {
    path: "permissionList",
    element: <PermissionList />,
    name: "Berechtigungen",
    icon: "",
  },
  {
    path: "permissionGroupList",
    element: <PermissionGroupList />,
    name: "Berechtigungen - Gruppen",
    icon: "",
  },
  {
    path: "auditlog",
    element: <AuditLog />,
    name: "Auditlog",
    icon: "",
  },
  // {
  //   path: "actionChains",
  //   element: <ActionChainList />,
  //   name: "Actionchains",
  //   icon: "",
  // },
  {
    path: "formEditor/:formId?",
    element: <FormEditor />,
  },
  {
    path: "probeEditor/:probeId?",
    element: <ProbeEditor />,
  },
  {
    path: "createMeasurementDefinition/:measurementDefinitionId?",
    element: <MeasurementDefinitionCreate />,
  },
  {
    path: "templateEditor/:templateid?",
    element: <TemplateEditor />,
  },
  {
    path: "appointmentEditor/:appointmentId?",
    element: <AppointmentEditor />,
  },
  {
    path: "mailTemplateEditor/:mailTemplateId?",
    element: <MailTemplateEditor />,
  },
  // {
  //   path: "actionChains/progress/:actionChainId?",
  //   element: <ActionChainProgress />,
  // },
  // {
  //   path: "actionChains/:actionChainId?",
  //   element: <ActionChainCreateAndEdit />,
  // },
];

const adminSubrouteContent = [
  {
    path: "userAdministration",
    element: <UserAdministration />,
    name: "Mitarbeiter",
    icon: "",
  },
  {
    path: "companyList",
    element: <CompanyList />,
    name: "Firma",
    icon: "",
  },
  {
    path: "todoList",
    element: <TodoList />,
    name: "Todo",
    icon: "",
  },

  {
    path: "coreData",
    element: <CoreDataView />,
    name: "Stammdaten",
    icon: "",
  },
  {
    path: "todoCreator",
    element: <TodoCreator />,
    name: "Todo Creator",
    icon: "",
  },

  {
    path: "teams",
    element: <TeamsList />,
    name: "Teams",
    icon: "",
  },
];

export {
  adminSubroutes,
  adminSubrouteContent,
  settingsSoubRoutes,
  settingsSubrouteContent,
  trackedServiceSubRoutes,
  trackedServiceSubRoutContent,
};
