import { Route, Routes } from "react-router-dom";
import { trackedServiceSubRoutContent } from "../components/navigation/adminSubRoutes";

export default function AdminTrackedService() {
  return (
    <>
      <Routes>
        {trackedServiceSubRoutContent.map((content: any) => (
          <Route
            path={content.path}
            element={content.element}
            key={content.path}
          />
        ))}
      </Routes>
    </>
  );
}
